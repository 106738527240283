import React from "react";
import { Link, Stack, Image, Icon } from "@chakra-ui/react";
import { Coupon, MerchantMethod } from "../../../fetchers/model";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../../utils/Table";
import { BsCardImage } from "react-icons/bs";

export default function ListMerchantCoupon({
  coupons,
}: {
  coupons: MerchantMethod<Coupon>[];
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<MerchantMethod<Coupon>[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
    }: {
      pageSize: number;
      pageIndex: number;
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setData(coupons.slice(startRow, endRow));
        setPageCount(Math.ceil(coupons.length / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const tableColumns = [
    {
      Header: t("coupon.name"),
      accessor: "name" as const,
    },
    {
      Header: t("coupon.issuer"),
      accessor: "issuer" as const,
    },
    {
      Header: t("coupon.discount_money"),
      accessor: "discount_money" as const,
    },
  ];

  const tableData = data.map((item) => ({
    name: (
      <Stack direction="row" spacing={2} alignItems="center">
        {item.method.logo && item.method.logo.id ? (
          <Image
            boxSize="36px"
            objectFit="cover"
            src={item.method.logo.url}
            alt={item.method.name}
          />
        ) : (
          <Icon as={BsCardImage} boxSize="36px" color={"gray.400"} />
        )}
        <Link
          color="blue.500"
          href={`/console/coupon/${item.method.id}`}
          fontWeight={"bold"}
        >
          {item.method.name}
        </Link>
      </Stack>
    ),
    issuer: item.method.issuer,
    discount_money:
      item.method.discount_type === "percentage"
        ? `${item.method.discount_money * 100}%`
        : item.method.discount_money,
  }));

  return (
    <>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
    </>
  );
}
