import React from "react";
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  Heading,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import { TimeIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Notification } from "../../fetchers/model";
import { FiEdit2 } from "react-icons/fi";
import { DataLoading, DataNotReach } from "../../utils";
import { ConvertDateToString } from "../../utils/Date";
import axios from "axios";
import API from "../../config/api";

export default function ShowNotification() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Notification>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<Notification>(
      `${API.NOTIFICATION}/${params.id}`
    );
    setData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  return (
    <Flex h="100vh" direction={"column"}>
      <Flex justifyContent="space-between" alignItems={"center"}>
        <Box>
          <Link
            color="blue.500"
            href={`/console/notification`}
            fontWeight={"semibold"}
          >
            {t("action.backToList")}
          </Link>
          <Heading as="h4" size="lg" mb="4">
            {`${data.title}`}
          </Heading>
        </Box>
        <Box>
          <Stack direction={"row"} spacing={4}>
            <Button
              size={"sm"}
              leftIcon={<FiEdit2 />}
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(`/console/notification/${data.id}/edit`)}
            >
              {t("action.edit")}
            </Button>
          </Stack>
        </Box>
      </Flex>
      <Box bg="white">
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <TimeIcon />
              <Text>
                {data.scheduled_send_time &&
                  ConvertDateToString(new Date(data.scheduled_send_time))}
              </Text>
            </Stack>
          </Stack>
        </SimpleGrid>
        <Text mt={4}>{data.description}</Text>
      </Box>
    </Flex>
  );
}
