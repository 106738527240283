import React from "react";
import {
  Stack,
  Icon,
  Text,
  Flex,
  Heading,
  Link,
  Input,
} from "@chakra-ui/react";
import { FiCreditCard, FiTarget } from "react-icons/fi";
import { RiCoupon2Line } from "react-icons/ri";
import {
  Recommend,
  RecommendPointCard,
  RecommendCoupon,
  RecommendPaymentMethod,
  PointCard,
  Coupon,
  ApplyingMethod,
  PaymentMethod,
  Merchant,
  User,
} from "../../fetchers/model";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../utils/Table";
import axios from "axios";
import { Select } from "chakra-react-select";
import { SelectOptions } from "../../utils/CommonInterface";
import { FilterValue, SortBy } from "../../utils/CommonInterface";
import { ConvertDateToString } from "../../utils/Date";
import API from "../../config/api";

export default function ListRecommendHistory() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Recommend[]>([]);
  const [merchantOptions, setMerchantOptions] = React.useState<SelectOptions[]>(
    []
  );
  const [userOptions, setUserOptions] = React.useState<SelectOptions[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchMerchant = async () => {
    const { data: result } = await axios.get<Merchant[]>(
      `${API.MERCHANT}?fields="id,company_name"`
    );
    setMerchantOptions(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.company_name,
        };
      })
    );
  };

  const fetchUser = async () => {
    const { data: result } = await axios.get<User[]>(
      `${API.USER}?fields="id,name"`
    );
    setUserOptions(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.name,
        };
      })
    );
  };

  React.useEffect(() => {
    fetchMerchant();
    fetchUser();
  }, []);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      globalFilter,
      filters,
      sortBy,
    }: {
      pageSize: number;
      pageIndex: number;
      globalFilter: string;
      filters: FilterValue[];
      sortBy: SortBy[];
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      const merchantFilters = filters.filter(
        (x) => x.id === "merchant" && x.value.length > 0
      );
      let merchantParams =
        (merchantFilters.length > 0 &&
          merchantFilters[0].value
            .map((x) => `&merchant_id[]=${x}`)
            .join("")) ||
        "";

      const userFilters = filters.filter(
        (x) => x.id === "user" && x.value.length > 0
      );
      let userParams =
        (userFilters.length > 0 &&
          userFilters[0].value.map((x) => `&user_id[]=${x}`).join("")) ||
        "";

      const rangeFilters = filters.filter((x) => x.id === "recommend_at");
      let fromParam =
        (rangeFilters.length > 0 &&
          rangeFilters[0].value[0] &&
          `&from_recommend_date=${ConvertDateToString(
            new Date(rangeFilters[0].value[0])
          )}`) ||
        "";
      let toParam =
        (rangeFilters.length > 0 &&
          rangeFilters[0].value.length > 1 &&
          rangeFilters[0].value[1] &&
          `&to_recommend_date=${ConvertDateToString(
            new Date(rangeFilters[0].value[1])
          )}`) ||
        "";

      let sortByParam =
        (sortBy.length > 0 &&
          `&order=${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`) ||
        "";

      const { data, headers } = await axios.get<Recommend[]>(
        `${API.HISTORY}?page=${pageIndex + 1}&per_page=${pageSize}${
          globalFilter ? `&keyword=${globalFilter}` : ""
        }${merchantParams}${userParams}${fromParam}${toParam}${sortByParam}`
      );
      if (fetchId === fetchIdRef.current) {
        setData(data);
        setPageCount(Math.ceil(parseInt(headers["total"]) / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const tableColumns = [
    {
      Header: t("history.recommend_at"),
      accessor: "recommend_at" as const,
      FilterHeader: t("history.date_range.title"),
      Filter: ({ column }: { column: any }) => (
        <Stack direction={"row"} spacing={4} alignItems="center">
          <Input
            size="sm"
            type="date"
            id="date_from"
            name="date_from"
            value={
              (column.filterValue &&
                column.filterValue[0] &&
                ConvertDateToString(new Date(column.filterValue[0]))) ||
              ""
            }
            onChange={(e) => {
              if (!column.filterValue || column.filterValue.length === 1) {
                column.setFilter([e.target.value]);
                return;
              }
              column.setFilter([e.target.value, column.filterValue[1]]);
            }}
          />
          <Text>{t("history.date_range.to")}</Text>
          <Input
            size="sm"
            type="date"
            id="date_to"
            name="date_to"
            value={
              (column.filterValue &&
                column.filterValue.length > 1 &&
                column.filterValue[1] &&
                ConvertDateToString(new Date(column.filterValue[1]))) ||
              ""
            }
            onChange={(e) => {
              column.setFilter([
                !column.filterValue ? null : column.filterValue[0],
                e.target.value,
              ]);
            }}
          />
        </Stack>
      ),
      filterable: true,
    },
    {
      Header: t("history.merchant"),
      accessor: "merchant" as const,
      FilterHeader: t("history.merchant"),
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={merchantOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={merchantOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: t("history.user"),
      accessor: "user" as const,
      FilterHeader: t("history.user"),
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={userOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={userOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: t("history.total_amount"),
      accessor: "total_amount" as const,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: t("history.recommend_1"),
      accessor: "recommend_1" as const,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: t("history.applying_methods"),
      accessor: "applying_methods" as const,
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const renderRecommendItem = (
    item: RecommendPointCard | RecommendCoupon | RecommendPaymentMethod,
    index: number
  ) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center" key={index}>
        <Icon
          as={
            item.method_type === "PointCard"
              ? FiTarget
              : item.method_type === "Coupon"
              ? RiCoupon2Line
              : FiCreditCard
          }
        />
        <Text>{`${item.name} - ${item.saving_amount}¥`}</Text>
      </Stack>
    );
  };

  const renderAppliedItem = (
    item: ApplyingMethod<PointCard | Coupon | PaymentMethod>,
    index: number
  ) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center" key={index}>
        <Icon
          as={
            item.method_type === "PointCard"
              ? FiTarget
              : item.method_type === "Coupon"
              ? RiCoupon2Line
              : FiCreditCard
          }
        />
        <Text>{`${item.method?.name ?? ""} - ${item.saving_amount}¥`}</Text>
      </Stack>
    );
  };

  const tableData = data.map((item) => ({
    recommend_at: (
      <Link
        color="blue.500"
        href={`/console/recommend_history/${item.token}`}
        fontWeight={"semibold"}
      >
        {new Date(item.recommend_at)
          .toISOString()
          .slice(0, 19)
          .replace(/-/g, "/")
          .replace("T", " ")}
      </Link>
    ),
    merchant: (
      <Link
        color="blue.500"
        href={`/console/merchant/${item.merchant.id}`}
        fontWeight={"semibold"}
      >
        {`${item.merchant.company_name}`}
      </Link>
    ),
    user: (
      <Link
        color="blue.500"
        href={`/console/user/${item.user.id}`}
        fontWeight={"semibold"}
      >
        {(item.user.first_name &&
          item.user.last_name &&
          `${item.user.first_name} ${item.user.last_name}`) ||
          t("user.name_unknown")}
      </Link>
    ),
    total_amount: item.total_amount,
    recommend_1: item.recommend_data[0] && (
      <Stack spacing={1}>
        {item.recommend_data[0].map((item, index) => {
          return item && renderRecommendItem(item, index);
        })}
      </Stack>
    ),
    applying_methods: item.applying_methods && (
      <Stack spacing={1}>
        {item.applying_methods.map((item, index) => {
          return item && renderAppliedItem(item, index);
        })}
      </Stack>
    ),
  }));

  return (
    <>
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" mb="4">
          {t("history.title.name")}
        </Heading>
      </Flex>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        useSearch={true}
        disableFilters={false}
        disableSortBy={false}
      />
    </>
  );
}
