export function ConvertDateToString(dateValue: Date) {
  const year = dateValue.getFullYear();
  const month = ("0" + (dateValue.getMonth() + 1)).slice(-2);
  const day = ("0" + dateValue.getDate()).slice(-2);

  return `${year}-${month}-${day}`;
}

export function ExtractTimeToString(dateValue: Date) {
  const hour = ("0" + dateValue.getHours()).slice(-2);
  const min = ("0" + dateValue.getMinutes()).slice(-2);

  return `${hour}:${min}`;
}
