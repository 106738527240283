import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  VStack,
  Stack,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import {
  User,
  Coupon,
  PaymentMethod,
  PointCard,
  UserMethod,
} from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import { Select } from "chakra-react-select";
import { SelectOptions } from "../../utils/CommonInterface";
import axios from "axios";
import API from "../../config/api";

export default function RegisterMethod() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<User>();
  const [methods, setMethods] = React.useState<SelectOptions[]>();
  const [selectedMethods, setSelectedMethods] = React.useState<string[]>([]);
  const [preferKind, setPreferKind] = React.useState<string>("return_rate");
  const [preferType, setPreferType] = React.useState<string>("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<User>(
      `${API.USER}/${params.id}?fields=user_methods,id,email,interested_method`
    );
    setPreferKind(result.interested_method.prefer_kind);
    setPreferType(result.interested_method.prefer_type);
    setData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (
      preferKind === "return_rate" &&
      !["credit_card", "point"].includes(preferType)
    )
      setPreferType("credit_card");
  }, [data, preferKind]);

  const fetchMethods = () => {
    let user_methods:
      | UserMethod<Coupon | PaymentMethod | PointCard>[]
      | undefined =
      preferKind === "return_discount"
        ? data?.user_methods.Coupon
        : preferType === "credit_card"
        ? data?.user_methods.PaymentMethod
        : data?.user_methods.PointCard;
    if (user_methods) {
      setMethods(
        user_methods.map(function (x) {
          return {
            value: x.method.id,
            label: x.method.name,
          };
        })
      );
      setSelectedMethods(
        user_methods
          .filter((x) => x.interested === true)
          .map((x) => x.method.id)
      );
    }
  };

  React.useEffect(() => {
    fetchMethods();
  }, [preferKind, preferType]);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  const handleSubmit = async () => {
    setSubmitting(true);

    const method_type =
      preferKind === "return_discount"
        ? "Coupon"
        : preferType === "credit_card"
        ? "PaymentMethod"
        : "PointCard";
    const postData = {
      method_type: method_type,
      method_ids: selectedMethods,
    };
    await axios.put<void>(
      `${API.USER}/${params.id}/methods/interested`,
      postData
    );
    toast({
      title: t("user.api.updated_success"),
      status: "success",
      isClosable: true,
    });
    setSubmitting(false);
    navigate(-1);
  };

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("user.title.interest", { email: data.email })}
      </Heading>
      <Box bg="white" rounded="md" w={360} pb={4}>
        <VStack spacing={4} align="flex-start">
          <FormControl isRequired>
            <FormLabel htmlFor="prefer_kind">
              {t("user.prefer_kind.title")}
            </FormLabel>
            <RadioGroup
              id="prefer_kind"
              name="prefer_kind"
              colorScheme="blue"
              value={preferKind}
              onChange={setPreferKind}
            >
              <Stack spacing={5} direction="row">
                {["return_rate", "return_discount"].map((x) => (
                  <Radio key={x} value={x}>
                    {t(`user.prefer_kind.${x}`)}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </FormControl>
          {preferKind === "return_rate" && (
            <FormControl isRequired>
              <FormLabel htmlFor="prefer_type">
                {t("user.prefer_type.title")}
              </FormLabel>
              <RadioGroup
                id="prefer_type"
                name="prefer_type"
                colorScheme="blue"
                value={preferType}
                onChange={setPreferType}
              >
                <Stack spacing={5} direction="row">
                  {["credit_card", "point"].map((x) => (
                    <Radio key={x} value={x}>
                      {t(`user.prefer_type.${x}`)}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </FormControl>
          )}
          <FormControl>
            <FormLabel htmlFor="method">
              {t("user.prefer_type.method")}
            </FormLabel>
            <Select
              size="sm"
              name="method"
              isClearable
              isMulti
              closeMenuOnSelect={false}
              value={
                methods &&
                methods.filter(
                  (x) => selectedMethods && selectedMethods.includes(x.value)
                )
              }
              options={methods}
              onChange={(option) =>
                setSelectedMethods((option && option.map((x) => x.value)) || [])
              }
            />
          </FormControl>
          <Stack direction="row" spacing={4} align="center">
            <Button
              colorScheme="blue"
              variant="solid"
              type="submit"
              isLoading={submitting}
              loadingText={t("action.loading.updating")}
              onClick={handleSubmit}
            >
              {t("action.update")}
            </Button>
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(-1)}
              disabled={submitting}
            >
              {t("action.cancel")}
            </Button>
          </Stack>
        </VStack>
      </Box>
    </Flex>
  );
}
