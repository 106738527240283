import React from "react";
import { Formik, Field, FieldProps } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Image,
  CloseButton,
  Text,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { PointCard } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import axios from "axios";
import API from "../../config/api";

export default function EditPointCard() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<PointCard>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  React.useEffect(() => {
    const fetchData = async () => {
      const { data: result } = await axios.get<PointCard>(
        `${API.POINT_CARD}/${params.id}`
      );
      setData(result);
      setLoading(false);
    };

    fetchData();
  }, [params]);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("point_card.title.edit")}
      </Heading>
      <Box bg="white" rounded="md" w={360} pb={4}>
        <Formik
          initialValues={{
            name: data.name,
            paid_amount: data.paid_amount || "",
            point_acquired: data.point_acquired || "",
            point_rate: data.point_rate || "1",
            amount_rate: data.amount_rate || "100",
            logo: data.logo && data.logo.id ? data.logo.url : null,
            isLogoChange: false,
            banner: data.banner && data.banner.id ? data.banner.url : null,
            isBannerChange: false,
            url: data.url || "",
            ios_app_id: data.ios_app_id || "",
            android_app_id: data.android_app_id || "",
            ios_url_scheme: data.ios_url_scheme || "",
            status: data.status,
            redemption_unit: data.redemption_unit,
          }}
          onSubmit={async (values, { setErrors }) => {
            setSubmitting(true);

            var bodyFormData = new FormData();
            Object.entries(values).forEach(([key, val]) => {
              if (
                !["logo", "banner", "isLogoChange", "isBannerChange"].includes(
                  key
                )
              )
                bodyFormData.append(key, val as string);
            });
            if (values.isLogoChange) {
              if (values.logo) bodyFormData.append("logo", values.logo);
              data.logo &&
                bodyFormData.append("destroy_upload_ids[]", data.logo?.id);
            }
            if (values.isBannerChange) {
              if (values.banner) bodyFormData.append("banner", values.banner);
              data.banner &&
                bodyFormData.append("destroy_upload_ids[]", data.banner?.id);
            }

            axios({
              method: "put",
              url: `${API.POINT_CARD}/${params.id}`,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(function (response) {
                toast({
                  title: t("point_card.api.updated_success"),
                  status: "success",
                  isClosable: true,
                });
                setSubmitting(false);
                navigate(-1);
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          }}
        >
          {({ handleSubmit, values, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={!!errors.name && touched.name}
                >
                  <FormLabel htmlFor="name">{t("point_card.name")}</FormLabel>
                  <Field as={Input} id="name" name="name" />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.url && touched.url}>
                  <FormLabel htmlFor="url">{t("point_card.url")}</FormLabel>
                  <Field as={Input} id="url" name="url" />
                  <FormErrorMessage>{errors.url}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.ios_app_id && touched.ios_app_id}
                >
                  <FormLabel htmlFor="ios_app_id">
                    {t("point_card.ios_app_id")}
                  </FormLabel>
                  <Field as={Input} id="ios_app_id" name="ios_app_id" />
                  <FormErrorMessage>{errors.ios_app_id}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.ios_url_scheme && touched.ios_url_scheme}
                >
                  <FormLabel htmlFor="ios_url_scheme">
                    {t("point_card.ios_url_scheme")}
                  </FormLabel>
                  <Field as={Input} id="ios_url_scheme" name="ios_url_scheme" />
                  <FormErrorMessage>{errors.ios_url_scheme}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.android_app_id && touched.android_app_id}
                >
                  <FormLabel htmlFor="android_app_id">
                    {t("point_card.android_app_id")}
                  </FormLabel>
                  <Field as={Input} id="android_app_id" name="android_app_id" />
                  <FormErrorMessage>{errors.android_app_id}</FormErrorMessage>
                </FormControl>
                <Field name="logo">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl isInvalid={!!errors.logo && touched.logo}>
                        <FormLabel htmlFor="logo">
                          {t("payment_method.logo")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={
                                  values.isLogoChange
                                    ? URL.createObjectURL(field.value)
                                    : field.value
                                }
                                boxSize="100px"
                                objectFit="cover"
                                alt="Logo"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => {
                                  setFieldValue("isLogoChange", true);
                                  setFieldValue(field.name, null);
                                }}
                              />
                            </Stack>
                          )}
                          <input
                            name="logo"
                            accept="image/*"
                            id="logo"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              fileReader.readAsDataURL(e.target.files[0]);
                              setFieldValue("isLogoChange", true);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>{errors.logo}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="banner">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl
                        isInvalid={!!errors.banner && touched.banner}
                      >
                        <FormLabel htmlFor="banner">
                          {t("payment_method.banner")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={
                                  values.isBannerChange
                                    ? URL.createObjectURL(field.value)
                                    : field.value
                                }
                                boxSize="100px"
                                objectFit="cover"
                                alt="banner"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => {
                                  setFieldValue("isBannerChange", true);
                                  setFieldValue(field.name, null);
                                }}
                              />
                            </Stack>
                          )}
                          <input
                            name="banner"
                            accept="image/*"
                            id="banner"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              setFieldValue("isBannerChange", true);
                              fileReader.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>{errors.banner}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <FormControl isRequired>
                  <FormLabel>{t("point_card.money_conversion")}</FormLabel>
                  <Stack direction={"row"} spacing={4} alignItems="center">
                    <Field name="paid_amount">
                      {({ field }: FieldProps) => {
                        return (
                          <NumberInput
                            value={field.value}
                            id="paid_amount"
                            name="paid_amount"
                            onChange={(val) => setFieldValue(field.name, val)}
                          >
                            <NumberInputField />
                          </NumberInput>
                        );
                      }}
                    </Field>
                    <Text w={50}>{` ¥ = `}</Text>
                    <Field name="point_acquired">
                      {({ field }: FieldProps) => {
                        return (
                          <NumberInput
                            value={field.value}
                            id="point_acquired"
                            name="point_acquired"
                            onChange={(val) => setFieldValue(field.name, val)}
                          >
                            <NumberInputField />
                          </NumberInput>
                        );
                      }}
                    </Field>
                    <Text>{`points`}</Text>
                  </Stack>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!errors.redemption_unit && touched.redemption_unit
                  }
                >
                  <FormLabel htmlFor="redemption_unit">
                    {t("point_card.redemption_unit")}
                  </FormLabel>
                  <Field
                    as={Input}
                    id="redemption_unit"
                    name="redemption_unit"
                  />
                  <FormErrorMessage>{errors.redemption_unit}</FormErrorMessage>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>{t("point_card.point_conversion")}</FormLabel>
                  <Stack direction={"row"} spacing={4} alignItems="center">
                    <Field name="point_rate">
                      {({ field }: FieldProps) => {
                        return (
                          <NumberInput
                            value={field.value}
                            id="point_rate"
                            name="point_rate"
                            onChange={(val) => setFieldValue(field.name, val)}
                          >
                            <NumberInputField />
                          </NumberInput>
                        );
                      }}
                    </Field>
                    <Text w={130}>{` points = `}</Text>
                    <Field name="amount_rate">
                      {({ field }: FieldProps) => {
                        return (
                          <NumberInput
                            value={field.value}
                            id="amount_rate"
                            name="amount_rate"
                            onChange={(val) => setFieldValue(field.name, val)}
                          >
                            <NumberInputField />
                          </NumberInput>
                        );
                      }}
                    </Field>
                    <Text>{values.redemption_unit}</Text>
                  </Stack>
                </FormControl>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    type="submit"
                    isLoading={submitting}
                    loadingText={t("action.loading.updating")}
                  >
                    {t("action.update")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => navigate(-1)}
                    disabled={submitting}
                  >
                    {t("action.cancel")}
                  </Button>
                </Stack>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}
