import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Tag,
  TagLabel,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  FormErrorMessage,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  Hide,
  Show,
  Icon,
} from "@chakra-ui/react";
import {
  InfoIcon,
  CheckIcon,
  SmallCloseIcon,
  EmailIcon,
  PhoneIcon,
  ChevronDownIcon,
  ViewIcon,
  LockIcon,
} from "@chakra-ui/icons";
import { MdCreditScore } from "react-icons/md";
import { Formik, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { Merchant } from "../../../fetchers/model";
import ListMerchantPaymentMethod from "./paymentMethod";
import ListMerchantPointCard from "./pointCard";
import ListMerchantCoupon from "./coupon";
import { DataLoading, DataNotReach } from "../../../utils";
import axios from "axios";
import API from "../../../config/api";
import { RiCoupon2Line } from "react-icons/ri";
import { FiCreditCard, FiEdit2, FiTarget } from "react-icons/fi";

export default function ShowMerchant() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Merchant>();
  const [status, setStatus] = React.useState<string>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = async () => {
    const { data: result } = await axios.get<Merchant>(
      `${API.MERCHANT}/${params.id}`
    );
    setData(result);
    setStatus(result.status);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const changeStatus = async (status: string, reason?: string) => {
    await axios.put<void>(`${API.MERCHANT}/${params.id}/change_status`, {
      status: status,
      reject_reason: reason,
    });
    setStatus(status);
    toast({
      title: t("merchant.api.update_status_success"),
      status: "success",
      isClosable: true,
    });
  };

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  return (
    <Flex h="100vh" direction={"column"}>
      <Flex justifyContent="space-between" alignItems={"center"}>
        <Box>
          <Link
            color="blue.500"
            href={`/console/merchant`}
            fontWeight={"semibold"}
          >
            {t("action.backToList")}
          </Link>
          <Heading as="h4" size="lg" mb="4">
            {`${data.company_name}`}
          </Heading>
        </Box>
        <Box>
          <Stack direction={"row"} spacing={4} alignItems="center">
            <Button
              size={"sm"}
              leftIcon={<FiEdit2 />}
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(`/console/merchant/${data.id}/edit`)}
            >
              {t("action.edit")}
            </Button>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size={"sm"}
                colorScheme="blue"
              >
                {t("action.change_status")}
              </MenuButton>
              <MenuList>
                {status !== "waiting_review" && (
                  <MenuItem
                    icon={<ViewIcon />}
                    onClick={() => changeStatus("waiting_review")}
                  >
                    {t("merchant.action.review")}
                  </MenuItem>
                )}
                {status !== "in_active" && (
                  <MenuItem
                    icon={<LockIcon />}
                    onClick={() => changeStatus("in_active")}
                  >
                    {t("merchant.action.deactivate")}
                  </MenuItem>
                )}
                {status !== "approved" && (
                  <MenuItem
                    icon={<CheckIcon />}
                    onClick={() => changeStatus("approved")}
                  >
                    {t("merchant.action.approve")}
                  </MenuItem>
                )}
                {status !== "rejected" && (
                  <MenuItem icon={<SmallCloseIcon />} onClick={onOpen}>
                    {t("merchant.action.reject")}
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          </Stack>
        </Box>
      </Flex>
      <Box bg="white">
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <EmailIcon />
              <Text>{data.email}</Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <PhoneIcon />
              <Text>{data.phone}</Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <InfoIcon />
              <Text>{data.person_in_charge}</Text>
            </Stack>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.office_name")}</Text>
              <Text fontWeight={"semibold"}>{data.office_name}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.representative_name")}</Text>
              <Text fontWeight={"semibold"}>{data.representative_name}</Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.status.title")}</Text>
              <Box>
                <Tag
                  borderRadius="full"
                  variant="solid"
                  colorScheme={
                    status === "waiting_review"
                      ? "yellow"
                      : status === "approved"
                      ? "blue"
                      : status === "rejected"
                      ? "red"
                      : "gray"
                  }
                  justifyContent="center"
                >
                  <TagLabel>{t(`merchant.status.${status}`)}</TagLabel>
                </Tag>
              </Box>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.tax")}</Text>
              <Text fontWeight={"semibold"}>{data.tax}</Text>
            </Box>
          </Stack>
        </SimpleGrid>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.address.country")}</Text>
              <Text fontWeight={"semibold"}>{data.country || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.address.postal_code")}</Text>
              <Text fontWeight={"semibold"}>{data.postal_code || "-"}</Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.address.province")}</Text>
              <Text fontWeight={"semibold"}>{data.province || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.address.district")}</Text>
              <Text fontWeight={"semibold"}>{data.district || "-"}</Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.address.address_1")}</Text>
              <Text fontWeight={"semibold"}>{data.address_1 || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("merchant.address.address_2")}</Text>
              <Text fontWeight={"semibold"}>{data.address_2 || "-"}</Text>
            </Box>
          </Stack>
        </SimpleGrid>
        <Tabs mt={4} colorScheme="blue" variant={"enclosed-colored"} isLazy>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <TabList>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={FiCreditCard} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.payment_method")}</Text>
                  </Hide>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={FiTarget} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.point_card")}</Text>
                  </Hide>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={RiCoupon2Line} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.coupon")}</Text>
                  </Hide>
                </Stack>
              </Tab>
            </TabList>
            <Button
              size={"sm"}
              leftIcon={<MdCreditScore />}
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(`/console/merchant/${data.id}/register`)}
            >
              {t("action.register")}
            </Button>
          </Stack>
          <TabPanels>
            <TabPanel>
              <ListMerchantPaymentMethod
                payment_methods={data.merchant_methods.PaymentMethod || []}
              />
            </TabPanel>
            <TabPanel>
              <ListMerchantPointCard
                point_cards={data.merchant_methods.PointCard || []}
              />
            </TabPanel>
            <TabPanel>
              <ListMerchantCoupon
                coupons={data.merchant_methods.Coupon || []}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("merchant.reject_form.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                reason: "",
              }}
              onSubmit={async (values, { setErrors }) => {
                changeStatus("rejected", values.reason);
                onClose();
              }}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack spacing={4} align="flex-start">
                    <FormControl isInvalid={!!errors.reason && touched.reason}>
                      <FormLabel htmlFor="reason">
                        {t("merchant.reject_form.reason")}
                      </FormLabel>
                      <Field as={Textarea} id="reason" name="reason"></Field>
                      <FormErrorMessage>{errors.reason}</FormErrorMessage>
                    </FormControl>
                    <Stack direction="row" spacing={4} align="center">
                      <Button colorScheme="red" mr={3} type="submit">
                        {t("merchant.action.reject")}
                      </Button>
                      <Button onClick={onClose} variant="ghost">
                        {t("action.cancel")}
                      </Button>
                    </Stack>
                  </VStack>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
