import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Link,
  Text,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { PointCard } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import axios from "axios";
import API from "../../config/api";

export default function ShowPointCard() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<PointCard>();
  const [status, setStatus] = React.useState<string>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<PointCard>(
      `${API.POINT_CARD}/${params.id}`
    );
    setData(result);
    setStatus(result.status);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const changeStatus = async (status: string) => {
    await axios.put<void>(`${API.POINT_CARD}/${params.id}/change_status`, {
      status: status,
    });
    setStatus(status);
    toast({
      title: t("point_card.api.update_status_success"),
      status: "success",
      isClosable: true,
    });
  };

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  return (
    <Flex h="100vh" direction={"column"}>
      <Flex justifyContent="space-between" alignItems={"center"}>
        <Box>
          <Link
            color="blue.500"
            href={`/console/point_card`}
            fontWeight={"semibold"}
          >
            {t("action.backToList")}
          </Link>
          <Heading as="h4" size="lg" mb="4">
            {data.name}
          </Heading>
        </Box>
        <Box>
          <Stack direction={"row"} spacing={4} alignItems="center">
            <Button
              size={"sm"}
              leftIcon={<FiEdit2 />}
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(`/console/point_card/${data.id}/edit`)}
            >
              {t("action.edit")}
            </Button>
            {status === "activate" && (
              <Button
                size={"sm"}
                leftIcon={<LockIcon />}
                colorScheme="gray"
                variant="solid"
                onClick={() => changeStatus("deactivate")}
              >
                {t("point_card.action.deactivate")}
              </Button>
            )}
            {status === "deactivate" && (
              <Button
                size={"sm"}
                leftIcon={<UnlockIcon />}
                colorScheme="blue"
                variant="solid"
                onClick={() => changeStatus("activate")}
              >
                {t("point_card.action.activate")}
              </Button>
            )}
          </Stack>
        </Box>
      </Flex>
      <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6} mt={2}>
        <Box>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("point_card.url")}</Text>
              <Text fontWeight={"semibold"}>{data.url || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("point_card.ios_app_id")}</Text>
              <Text fontWeight={"semibold"}>{data.ios_app_id || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("point_card.ios_url_scheme")}</Text>
              <Text fontWeight={"semibold"}>{data.ios_url_scheme || "-"}</Text>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("point_card.status.title")}</Text>
              <Box>
                <Tag
                  borderRadius="full"
                  variant="solid"
                  colorScheme={status === "activate" ? "blue" : "gray"}
                  justifyContent="center"
                >
                  <TagLabel>{t(`point_card.status.${status}`)}</TagLabel>
                </Tag>
              </Box>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("point_card.android_app_id")}</Text>
              <Text fontWeight={"semibold"}>{data.android_app_id || "-"}</Text>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("point_card.money_conversion")}</Text>
              <Text
                fontWeight={"semibold"}
              >{`${data.paid_amount} ¥ = ${data.point_acquired} points`}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("point_card.point_conversion")}</Text>
              <Text fontWeight={"semibold"}>{`${data.point_rate} points = ${
                data.amount_rate
              } ${data.redemption_unit || "¥"}`}</Text>
            </Box>
          </Stack>
        </Box>
      </SimpleGrid>
      <Accordion defaultIndex={[0, 1, 2]} allowMultiple mt={4}>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={"semibold"}>
                {t("point_card.description")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>{data.description}</AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={"semibold"}>
                {t("point_card.logo")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {data.logo?.id ? (
              <Image
                src={data.logo.url}
                maxHeight="50px"
                objectFit="cover"
                alt="Logo"
                mb={2}
              />
            ) : (
              t("point_card.no_logo")
            )}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontWeight={"semibold"}>
                {t("point_card.banner")}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {data.banner?.id ? (
              <Image
                src={data.banner.url}
                maxHeight="200px"
                objectFit="cover"
                alt="Banner"
                mb={2}
              />
            ) : (
              t("point_card.no_banner")
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}
