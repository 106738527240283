import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  VStack,
  Stack,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { Term } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import axios from "axios";
import API from "../../config/api";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/css/draft.css";

export default function EditTerm() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [term_no, setTermNo] = React.useState<string>("");
  const [force_read, setForceRead] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Term>();
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<Term>(`${API.TERM}/${params.id}`);
    setData(result);

    const blocksFromHTML = convertFromHTML(result.term_content);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        )
      )
    );
    setTermNo(result.term_no);
    setForceRead(result.force_read);

    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  const handleSubmit = async () => {
    setSubmitting(true);

    await axios.patch<void>(`${API.TERM}/${params.id}`, {
      term_content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      status: data.status,
      term_no: term_no,
      force_read: force_read,
    });
    toast({
      title: t("term.api.updated_success"),
      status: "success",
      isClosable: true,
    });
    setSubmitting(false);
    navigate(-1);
  };

  const toolbar = {
    options: [
      "history",
      "fontFamily",
      "blockType",
      "fontSize",
      "inline",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "remove",
    ],
  };

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("term.title.edit")}
      </Heading>
      <Box bg="white" rounded="md" pb={4}>
        <VStack spacing={4} align="flex-start">
          <Box w={360}>
            <FormControl isRequired>
              <FormLabel htmlFor="term_no">{t("term.term_no")}</FormLabel>
              <Input
                id="term_no"
                name="term_no"
                value={term_no}
                onChange={(e) => setTermNo(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <Checkbox
                id="force_read"
                name="force_read"
                colorScheme="blue"
                isChecked={force_read}
                onChange={() => setForceRead(!force_read)}
              >
                {t("term.force_read.title")}
              </Checkbox>
            </FormControl>
          </Box>
          <FormControl isRequired>
            <FormLabel htmlFor="term_content">
              {t("term.term_content")}
            </FormLabel>
            <Editor
              editorState={editorState}
              wrapperClassName="draft-wrapper"
              editorClassName="draft-editor"
              onEditorStateChange={setEditorState}
              toolbar={toolbar}
            />
          </FormControl>
          <Stack direction="row" spacing={4} align="center">
            <Button
              colorScheme="blue"
              variant="solid"
              type="submit"
              isLoading={submitting}
              loadingText={t("action.loading.updating")}
              onClick={handleSubmit}
            >
              {t("action.update")}
            </Button>
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(-1)}
              disabled={submitting}
            >
              {t("action.cancel")}
            </Button>
          </Stack>
        </VStack>
      </Box>
    </Flex>
  );
}
