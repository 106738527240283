import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  VStack,
  Stack,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import API from "../../config/api";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../assets/css/draft.css";

export default function CreateTerm() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [term_no, setTermNo] = React.useState<string>("");
  const [force_read, setForceRead] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const handleSubmit = async () => {
    setSubmitting(true);

    await axios.post<void>(API.TERM, {
      term_content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      status: "draft",
      term_no: term_no,
      force_read: force_read,
    });
    toast({
      title: t("term.api.created_success"),
      status: "success",
      isClosable: true,
    });
    setSubmitting(false);
    navigate(-1);
  };

  const toolbar = {
    options: [
      "history",
      "fontFamily",
      "blockType",
      "fontSize",
      "inline",
      "list",
      "textAlign",
      "colorPicker",
      "link",
      "remove",
    ],
  };

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("term.title.new")}
      </Heading>
      <Box bg="white" rounded="md" pb={4}>
        <VStack spacing={4} align="flex-start">
          <Box w={360}>
            <FormControl isRequired>
              <FormLabel htmlFor="term_no">{t("term.term_no")}</FormLabel>
              <Input
                id="term_no"
                name="term_no"
                value={term_no}
                onChange={(e) => setTermNo(e.target.value)}
              />
            </FormControl>
            <FormControl mt={4}>
              <Checkbox
                id="force_read"
                name="force_read"
                colorScheme="blue"
                isChecked={force_read}
                onChange={() => setForceRead(!force_read)}
              >
                {t("term.force_read.title")}
              </Checkbox>
            </FormControl>
          </Box>
          <FormControl isRequired>
            <FormLabel htmlFor="term_content">
              {t("term.term_content")}
            </FormLabel>
            <Editor
              editorState={editorState}
              wrapperClassName="draft-wrapper"
              editorClassName="draft-editor"
              onEditorStateChange={setEditorState}
              toolbar={toolbar}
            />
          </FormControl>
          <Stack direction="row" spacing={4} align="center">
            <Button
              colorScheme="blue"
              variant="solid"
              isLoading={submitting}
              loadingText={t("action.loading.creating")}
              onClick={handleSubmit}
            >
              {t("action.create")}
            </Button>
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(-1)}
              disabled={submitting}
            >
              {t("action.cancel")}
            </Button>
          </Stack>
        </VStack>
      </Box>
    </Flex>
  );
}
