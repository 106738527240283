const API = {
  DASHBOARD_VISITOR: "admins/dashboard/visitors",
  DASHBOARD_REVENUE: "admins/dashboard/revenues",
  DASHBOARD_POPULAR: "admins/dashboard/payment_methods",
  COUPON: "admins/coupons",
  MERCHANT: "admins/merchants",
  NOTIFICATION: "admins/notifications",
  PAYMENT_METHOD: "admins/payment_methods",
  POINT_CARD: "admins/point_cards",
  TERM: "admins/term_conditions",
  USER: "admins/users",
  HISTORY: "admins/recommend_histories",
  EVENT: "admins/event_marketings",
};

export default API;
