import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  VStack,
  Stack,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { User, Coupon, PaymentMethod, PointCard } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import { Select } from "chakra-react-select";
import { SelectOptions } from "../../utils/CommonInterface";
import axios from "axios";
import API from "../../config/api";

export default function RegisterMethod() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<User>();
  const [paymentMethods, setPaymentMethods] = React.useState<SelectOptions[]>();
  const [selectedPaymentMethods, setSelectedPaymentMethods] = React.useState<
    string[]
  >([]);
  const [coupons, setCoupons] = React.useState<SelectOptions[]>();
  const [selectedCoupons, setSelectedCoupons] = React.useState<string[]>([]);
  const [pointCards, setPointCards] = React.useState<SelectOptions[]>();
  const [selectedPointCards, setSelectedPointCards] = React.useState<string[]>(
    []
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<User>(
      `${API.USER}/${params.id}?fields=user_methods,id,email`
    );
    setData(result);
    setSelectedPaymentMethods(
      result.user_methods.PaymentMethod &&
        result.user_methods.PaymentMethod.map((x) => x.method.id)
    );
    setSelectedCoupons(
      result.user_methods.Coupon &&
        result.user_methods.Coupon.map((x) => x.method.id)
    );
    setSelectedPointCards(
      result.user_methods.PointCard &&
        result.user_methods.PointCard.map((x) => x.method.id)
    );
    setLoading(false);
  };

  const fetchPaymentMethods = async () => {
    const { data: result } = await axios.get<PaymentMethod[]>(
      `${API.PAYMENT_METHOD}?&status[]=activate`
    );
    setPaymentMethods(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.name,
        };
      })
    );
  };

  const fetchCoupons = async () => {
    const { data: result } = await axios.get<Coupon[]>(
      `${API.COUPON}?&status[]=activate`
    );
    setCoupons(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.name,
        };
      })
    );
  };

  const fetchPointCards = async () => {
    const { data: result } = await axios.get<PointCard[]>(
      `${API.POINT_CARD}?&status[]=activate`
    );
    setPointCards(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.name,
        };
      })
    );
  };

  React.useEffect(() => {
    fetchPaymentMethods();
    fetchCoupons();
    fetchPointCards();
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data || !paymentMethods || !coupons || !pointCards)
    return <DataNotReach />;

  const handleSubmit = async () => {
    setSubmitting(true);

    const postData = {
      coupon_ids: selectedCoupons,
      payment_method_ids: selectedPaymentMethods,
      point_card_ids: selectedPointCards,
    };
    await axios.post<void>(
      `${API.USER}/${params.id}/methods/register`,
      postData
    );
    toast({
      title: t("user.api.updated_success"),
      status: "success",
      isClosable: true,
    });
    setSubmitting(false);
    navigate(-1);
  };

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("user.title.register", { email: data.email })}
      </Heading>
      <Box bg="white" rounded="md" w={360} pb={4}>
        <VStack spacing={4} align="flex-start">
          <FormControl>
            <FormLabel htmlFor="PaymentMethod">
              {t("user.tab.payment_method")}
            </FormLabel>
            <Select
              size="sm"
              name="PaymentMethod"
              isClearable
              isMulti
              closeMenuOnSelect={false}
              value={paymentMethods.filter(
                (x) =>
                  selectedPaymentMethods &&
                  selectedPaymentMethods.includes(x.value)
              )}
              options={paymentMethods}
              onChange={(option) =>
                setSelectedPaymentMethods(
                  (option && option.map((x) => x.value)) || []
                )
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="Coupon">{t("user.tab.coupon")}</FormLabel>
            <Select
              size="sm"
              name="Coupon"
              isClearable
              isMulti
              closeMenuOnSelect={false}
              value={coupons.filter(
                (x) => selectedCoupons && selectedCoupons.includes(x.value)
              )}
              options={coupons}
              onChange={(option) =>
                setSelectedCoupons((option && option.map((x) => x.value)) || [])
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="PointCard">
              {t("user.tab.point_card")}
            </FormLabel>
            <Select
              size="sm"
              name="PointCard"
              isClearable
              isMulti
              closeMenuOnSelect={false}
              value={pointCards.filter(
                (x) =>
                  selectedPointCards && selectedPointCards.includes(x.value)
              )}
              options={pointCards}
              onChange={(option) =>
                setSelectedPointCards(
                  (option && option.map((x) => x.value)) || []
                )
              }
            />
          </FormControl>
          <Stack direction="row" spacing={4} align="center">
            <Button
              colorScheme="blue"
              variant="solid"
              type="submit"
              isLoading={submitting}
              loadingText={t("action.loading.updating")}
              onClick={handleSubmit}
            >
              {t("action.update")}
            </Button>
            <Button
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(-1)}
              disabled={submitting}
            >
              {t("action.cancel")}
            </Button>
          </Stack>
        </VStack>
      </Box>
    </Flex>
  );
}
