import React from "react";
import {
  Flex,
  Heading,
  Link,
  Button,
  Input,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { Merchant, EventMarketing } from "../../fetchers/model";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../utils/Table";
import axios from "axios";
import { Select } from "chakra-react-select";
import { SelectOptions } from "../../utils/CommonInterface";
import { FilterValue, SortBy } from "../../utils/CommonInterface";
import { ConvertDateToString, ExtractTimeToString } from "../../utils/Date";
import API from "../../config/api";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function ListEventMarketing() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<EventMarketing[]>([]);
  const [merchantOptions, setMerchantOptions] = React.useState<SelectOptions[]>(
    []
  );
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchMerchant = async () => {
    const { data: result } = await axios.get<Merchant[]>(
      `${API.MERCHANT}?fields="id,company_name"`
    );
    setMerchantOptions(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.company_name,
        };
      })
    );
  };

  React.useEffect(() => {
    fetchMerchant();
  }, []);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      globalFilter,
      filters,
      sortBy,
    }: {
      pageSize: number;
      pageIndex: number;
      globalFilter: string;
      filters: FilterValue[];
      sortBy: SortBy[];
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      const merchantFilters = filters.filter(
        (x) => x.id === "merchant" && x.value.length > 0
      );
      let merchantParams =
        (merchantFilters.length > 0 &&
          merchantFilters[0].value
            .map((x) => `&merchant_id[]=${x}`)
            .join("")) ||
        "";

      const typeFilters = filters.filter(
        (x) => x.id === "event_type" && x.value.length > 0
      );
      let typeParams =
        (typeFilters.length > 0 &&
          typeFilters[0].value.map((x) => `&event_type[]=${x}`).join("")) ||
        "";

      const fromFilters = filters.filter(
        (x) => x.id === "start_at" && x.value.length > 0
      );
      let fromParam =
        (fromFilters.length > 0 &&
          fromFilters[0].value
            .map(
              (x) =>
                x !== "" && `&from_date=${ConvertDateToString(new Date(x))}`
            )
            .join("")) ||
        "";
      const toFilters = filters.filter(
        (x) => x.id === "end_at" && x.value.length > 0
      );
      let toParam =
        (toFilters.length > 0 &&
          toFilters[0].value
            .map(
              (x) => x !== "" && `&to_date=${ConvertDateToString(new Date(x))}`
            )
            .join("")) ||
        "";

      let sortByParam =
        (sortBy.length > 0 &&
          `&order=${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`) ||
        "";

      const { data, headers } = await axios.get<EventMarketing[]>(
        `${API.EVENT}?page=${pageIndex + 1}&per_page=${pageSize}${
          globalFilter ? `&keyword=${globalFilter}` : ""
        }${merchantParams}${typeParams}${fromParam}${toParam}${sortByParam}`
      );
      if (fetchId === fetchIdRef.current) {
        setData(data);
        setPageCount(Math.ceil(parseInt(headers["total"]) / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const eventTypeOptions = [
    "event_campaign",
    "event_coupon",
    "event_timesale",
  ].map(function (val) {
    return {
      label: t(`event.event_type.${val}`),
      value: val,
    };
  });

  const tableColumns = [
    {
      Header: t("event.start_at"),
      accessor: "start_at" as const,
      FilterHeader: t("event.date_from"),
      Filter: ({ column }: { column: any }) => (
        <Input
          size="sm"
          type="date"
          id="date_from"
          name="date_from"
          value={(column.filterValue && column.filterValue[0]) || ""}
          onChange={(e) => column.setFilter([e.target.value])}
        />
      ),
      filterable: true,
    },
    {
      Header: t("event.end_at"),
      accessor: "end_at" as const,
      FilterHeader: t("event.date_to"),
      Filter: ({ column }: { column: any }) => (
        <Input
          size="sm"
          type="date"
          id="date_to"
          name="date_to"
          value={(column.filterValue && column.filterValue[0]) || ""}
          onChange={(e) => column.setFilter([e.target.value])}
        />
      ),
      filterable: true,
    },
    {
      Header: t("event.merchant"),
      accessor: "merchant" as const,
      FilterHeader: t("event.merchant"),
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={merchantOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={merchantOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: t("event.name"),
      accessor: "title" as const,
      disableFilters: true,
    },
    {
      Header: t("event.event_type.title"),
      accessor: "event_type" as const,
      FilterHeader: t("event.event_type.title"),
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={eventTypeOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={eventTypeOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: t("event.discount_money"),
      accessor: "discount_money" as const,
      disableFilters: true,
    },
    {
      Header: t("event.delivered_status.title"),
      accessor: "delivered_status" as const,
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const tableData = data.map((item) => ({
    start_at:
      item.start_at &&
      new Date(item.start_at)
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, "/")
        .replace("T", " "),
    end_at:
      item.end_at &&
      new Date(item.end_at)
        .toISOString()
        .slice(0, 19)
        .replace(/-/g, "/")
        .replace("T", " "),
    merchant: (
      <Link
        color="blue.500"
        href={`/console/merchant/${item.merchant.id}`}
        fontWeight={"semibold"}
      >
        {`${item.merchant.company_name}`}
      </Link>
    ),
    title: (
      <Link
        color="blue.500"
        href={`/console/event_marketing/${item.id}`}
        fontWeight={"bold"}
      >
        {`${item.title}`}
      </Link>
    ),
    event_type: t(`event.event_type.${item.event_type}`),
    discount_money: item.discount_money,
    delivered_status: (
      <Tag
        size={"sm"}
        borderRadius="full"
        variant="solid"
        colorScheme={item.delivered_at ? "blue" : "gray"}
      >
        <TagLabel>
          {t(
            `event.delivered_status.${
              item.delivered_at ? "delivered" : "not_deliver"
            }`
          )}
        </TagLabel>
      </Tag>
    ),
  }));

  return (
    <>
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" mb="4">
          {t("event.title.name")}
        </Heading>
        <Button
          leftIcon={<FiPlus />}
          colorScheme="blue"
          variant="solid"
          onClick={() => navigate("/console/event_marketing/new")}
        >
          {t("action.new")}
        </Button>
      </Flex>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        useSearch={true}
        disableFilters={false}
        disableSortBy={false}
      />
    </>
  );
}
