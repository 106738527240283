import React from "react";
import { Formik, Field, FieldProps } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Image,
  CloseButton,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import API from "../../config/api";
import { Merchant } from "../../fetchers/model";
import { SelectOptions } from "../../utils/CommonInterface";
import { Select } from "chakra-react-select";

export default function CreateEventMarketing() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [merchants, setMerchants] = React.useState<SelectOptions[]>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  const fetchMerchants = async () => {
    const { data: result } = await axios.get<Merchant[]>(
      `${API.MERCHANT}?status[]=approved`
    );
    setMerchants(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.company_name,
        };
      })
    );
  };

  React.useEffect(() => {
    fetchMerchants();
  }, []);

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("event.title.new")}
      </Heading>
      <Box bg="white" rounded="md" w={360} pb={4}>
        <Formik
          initialValues={{
            merchant_ids: Array<string>(),
            title: "",
            description: "",
            event_type: "event_campaign",
            reference: "",
            start_at: "",
            end_at: "",
            scheduled_send_time: "",
            apply_time: "",
            broadcast_radius: "1",
            discount_type: "percentage",
            discount_money: "0",
            thumbnails: null,
            force_send: false,
          }}
          onSubmit={async (values, { setErrors }) => {
            setSubmitting(true);

            var bodyFormData = new FormData();
            Object.entries(values).forEach(([key, val]) => {
              if (key !== "thumbnails" && key !== "merchant_ids")
                bodyFormData.append(key, val as string);
            });
            if (values.thumbnails) {
              bodyFormData.append("thumbnails", values.thumbnails);
            }
            values.merchant_ids.forEach((x) => {
              bodyFormData.append("merchant_ids[]", x);
            });

            axios({
              method: "post",
              url: API.EVENT,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(function (response) {
                toast({
                  title: t("event.api.created_success"),
                  status: "success",
                  isClosable: true,
                });
                setSubmitting(false);
                navigate(-1);
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          }}
        >
          {({ handleSubmit, setFieldValue, values, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={!!errors.merchant_ids && touched.merchant_ids}
                >
                  <FormLabel htmlFor="merchant_ids">
                    {t("event.merchant")}
                  </FormLabel>
                  <Select
                    size="sm"
                    name="merchant_ids"
                    isClearable
                    isMulti
                    closeMenuOnSelect={false}
                    value={
                      merchants &&
                      merchants.filter((x) =>
                        values.merchant_ids.includes(x.value)
                      )
                    }
                    options={merchants}
                    onChange={(option) =>
                      setFieldValue(
                        "merchant_ids",
                        option.map((x) => x.value)
                      )
                    }
                  />
                  <FormErrorMessage>{errors.merchant_ids}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={!!errors.title && touched.title}
                >
                  <FormLabel htmlFor="title">{t("event.name")}</FormLabel>
                  <Field as={Input} id="title" name="title" />
                  <FormErrorMessage>{errors.title}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.description && touched.description}
                >
                  <FormLabel htmlFor="description">
                    {t("event.description")}
                  </FormLabel>
                  <Field as={Input} id="description" name="description" />
                  <FormErrorMessage>{errors.description}</FormErrorMessage>
                </FormControl>
                <Field name="event_type">
                  {({ field, form }: FieldProps) => {
                    const { onChange, ...rest } = field;
                    return (
                      <FormControl
                        isRequired
                        isInvalid={
                          !!form.errors.event_type && !!form.touched.event_type
                        }
                      >
                        <FormLabel htmlFor="event_type">
                          {t("event.event_type.title")}
                        </FormLabel>
                        <RadioGroup
                          {...rest}
                          id="event_type"
                          name="event_type"
                          colorScheme="blue"
                        >
                          <Stack spacing={5} direction="row">
                            {[
                              "event_campaign",
                              "event_coupon",
                              "event_timesale",
                            ].map((x) => (
                              <Radio key={x} onChange={onChange} value={x}>
                                {t(`event.event_type.${x}`)}
                              </Radio>
                            ))}
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                </Field>
                <FormControl
                  isInvalid={!!errors.reference && touched.reference}
                >
                  <FormLabel htmlFor="reference">
                    {t("event.reference")}
                  </FormLabel>
                  <Field as={Input} id="reference" name="reference" />
                  <FormErrorMessage>{errors.reference}</FormErrorMessage>
                </FormControl>
                <Field name="discount_money">
                  {({ field, form }: FieldProps) => {
                    return (
                      <FormControl
                        isRequired
                        isInvalid={
                          !!errors.discount_money && touched.discount_money
                        }
                      >
                        <FormLabel htmlFor="discount_money">
                          {t("event.discount_money")}
                        </FormLabel>
                        <NumberInput
                          {...field}
                          id="discount_money"
                          name="discount_money"
                          onChange={(val) =>
                            form.setFieldValue(field.name, val)
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                        <FormErrorMessage>
                          {errors.discount_money}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <FormControl
                  isRequired
                  isInvalid={
                    !!errors.broadcast_radius && touched.broadcast_radius
                  }
                >
                  <FormLabel htmlFor="broadcast_radius">
                    {t("event.broadcast_radius")}
                  </FormLabel>
                  <Field
                    as={Input}
                    id="broadcast_radius"
                    name="broadcast_radius"
                  />
                  <FormErrorMessage>{errors.broadcast_radius}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.start_at && touched.start_at}>
                  <FormLabel htmlFor="start_at">
                    {t("event.start_at")}
                  </FormLabel>
                  <Field
                    as={Input}
                    type="datetime-local"
                    id="start_at"
                    name="start_at"
                  />
                  <FormErrorMessage>{errors.start_at}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.end_at && touched.end_at}>
                  <FormLabel htmlFor="end_at">{t("event.end_at")}</FormLabel>
                  <Field
                    as={Input}
                    type="datetime-local"
                    id="end_at"
                    name="end_at"
                  />
                  <FormErrorMessage>{errors.end_at}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    !!errors.scheduled_send_time && touched.scheduled_send_time
                  }
                >
                  <FormLabel htmlFor="scheduled_send_time">
                    {t("event.scheduled_send_time")}
                  </FormLabel>
                  <Field
                    as={Input}
                    type="datetime-local"
                    id="scheduled_send_time"
                    name="scheduled_send_time"
                  />
                  <FormErrorMessage>
                    {errors.scheduled_send_time}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.apply_time && touched.apply_time}
                >
                  <FormLabel htmlFor="apply_time">
                    {t("event.apply_time")}
                  </FormLabel>
                  <Field
                    as={Input}
                    type="time"
                    id="apply_time"
                    name="apply_time"
                  />
                  <FormErrorMessage>{errors.apply_time}</FormErrorMessage>
                </FormControl>
                <Field
                  name="thumbnails"
                  validate={(value: string) => {
                    let error = value
                      ? null
                      : t("event.validate.missing_thumbnails");

                    return error;
                  }}
                >
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.thumbnails && touched.thumbnails}
                      >
                        <FormLabel htmlFor="thumbnails">
                          {t("event.thumbnails")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={URL.createObjectURL(field.value)}
                                boxSize="100px"
                                objectFit="cover"
                                alt="Logo"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => setFieldValue(field.name, null)}
                              />
                            </Stack>
                          )}
                          <input
                            name="thumbnails"
                            accept="image/*"
                            id="thumbnails"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              fileReader.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>{errors.thumbnails}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="force_send">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl>
                        <Checkbox
                          id="force_send"
                          name="force_send"
                          colorScheme="blue"
                          isChecked={field.value}
                          onChange={() =>
                            setFieldValue(field.name, !field.value)
                          }
                        >
                          {t("notification.force_send")}
                        </Checkbox>
                      </FormControl>
                    );
                  }}
                </Field>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    type="submit"
                    isLoading={submitting}
                    loadingText={t("action.loading.creating")}
                  >
                    {t("action.create")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => navigate(-1)}
                    disabled={submitting}
                  >
                    {t("action.cancel")}
                  </Button>
                </Stack>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}
