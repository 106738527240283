import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Tag,
  TagLabel,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Link,
  FormErrorMessage,
  SimpleGrid,
  Hide,
  Show,
  Icon,
} from "@chakra-ui/react";
import {
  EditIcon,
  CheckIcon,
  SmallCloseIcon,
  EmailIcon,
  CalendarIcon,
  PhoneIcon,
} from "@chakra-ui/icons";
import { Formik, Field } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { User } from "../../../fetchers/model";
import ListUserPaymentMethod from "./paymentMethod";
import ListUserPointCard from "./pointCard";
import ListUserCoupon from "./coupon";
import ListImage from "./gallery";
import ListUserHistory from "./history";
import { DataLoading, DataNotReach } from "../../../utils";
import axios from "axios";
import API from "../../../config/api";
import { MdCreditScore, MdHistory } from "react-icons/md";
import { RiCoupon2Line, RiUserHeartLine } from "react-icons/ri";
import { FiCreditCard, FiEdit2, FiTarget } from "react-icons/fi";
import { BsCardImage } from "react-icons/bs";

export default function ShowUser() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<User>();
  const [kycStatus, setKycStatus] = React.useState<string>();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData = async () => {
    const { data: result } = await axios.get<User>(`${API.USER}/${params.id}`);
    setData(result);
    setKycStatus(result.kyc_status);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  const changeKycStatus = async (status: string, reason?: string) => {
    await axios.put<void>(`${API.USER}/${params.id}/change_kyc_status`, {
      kyc_status: status,
      reject_reason: reason,
    });
    setKycStatus(status);
    toast({
      title: t("user.api.update_kyc_status_success"),
      status: "success",
      isClosable: true,
    });
  };

  const renderActions = () => {
    return (
      <Stack direction={"row"} spacing={4}>
        <Button
          size={"sm"}
          leftIcon={<MdCreditScore />}
          colorScheme="blue"
          variant="ghost"
          onClick={() => navigate(`/console/user/${data.id}/register`)}
        >
          {t("action.register")}
        </Button>
        <Button
          size={"sm"}
          leftIcon={<RiUserHeartLine />}
          colorScheme="blue"
          variant="ghost"
          onClick={() => navigate(`/console/user/${data.id}/interest`)}
        >
          {t("action.interest")}
        </Button>
      </Stack>
    );
  };

  return (
    <Flex h="100vh" direction={"column"}>
      <Flex justifyContent="space-between" alignItems={"center"}>
        <Box>
          <Link color="blue.500" href={`/console/user`} fontWeight={"semibold"}>
            {t("action.backToList")}
          </Link>
          <Heading as="h4" size="lg" mb="4">
            {`${data.name || t("user.name_unknown")}`}
          </Heading>
        </Box>
        <Box>
          <Stack direction={"row"} spacing={4} alignItems="center">
            <Button
              size={"sm"}
              leftIcon={<FiEdit2 />}
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(`/console/user/${data.id}/edit`)}
            >
              {t("action.edit")}
            </Button>
            {kycStatus === "waiting_review" && (
              <Button
                size={"sm"}
                leftIcon={<EditIcon />}
                colorScheme="yellow"
                variant="solid"
                onClick={() => changeKycStatus("in_review")}
              >
                {t("user.kyc_action.in_review")}
              </Button>
            )}
            {kycStatus === "in_review" && (
              <Stack direction={"row"} spacing={4}>
                <Button
                  size={"sm"}
                  leftIcon={<CheckIcon />}
                  colorScheme="blue"
                  variant="solid"
                  onClick={() => changeKycStatus("approved")}
                >
                  {t("user.kyc_action.approve")}
                </Button>
                <Button
                  size={"sm"}
                  leftIcon={<SmallCloseIcon />}
                  colorScheme="red"
                  variant="solid"
                  onClick={onOpen}
                >
                  {t("user.kyc_action.reject")}
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </Flex>
      <Box bg="white">
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <EmailIcon />
              <Text>{data.email}</Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <CalendarIcon />
              <Text>{data.birth_date}</Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <PhoneIcon />
              <Text>{data.phone}</Text>
            </Stack>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Text fontSize={"sm"}>{t("user.kyc_status.title")}</Text>
            <Box>
              <Tag
                borderRadius="full"
                variant="solid"
                colorScheme={
                  kycStatus === "in_review"
                    ? "yellow"
                    : kycStatus === "approved"
                    ? "blue"
                    : kycStatus === "rejected"
                    ? "red"
                    : kycStatus === "waiting_review"
                    ? "pink"
                    : "gray"
                }
                justifyContent="center"
              >
                <TagLabel>{t(`user.kyc_status.${kycStatus}`)}</TagLabel>
              </Tag>
            </Box>
          </Stack>
        </SimpleGrid>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("user.address.country")}</Text>
              <Text fontWeight={"semibold"}>{data.country || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("user.address.postal_code")}</Text>
              <Text fontWeight={"semibold"}>{data.postal_code || "-"}</Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("user.address.province")}</Text>
              <Text fontWeight={"semibold"}>{data.province || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("user.address.district")}</Text>
              <Text fontWeight={"semibold"}>{data.district || "-"}</Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("user.address.address_1")}</Text>
              <Text fontWeight={"semibold"}>{data.address_1 || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("user.address.address_2")}</Text>
              <Text fontWeight={"semibold"}>{data.address_2 || "-"}</Text>
            </Box>
          </Stack>
        </SimpleGrid>
        <Show below="md">{renderActions()}</Show>
        <Tabs mt={4} colorScheme="blue" variant={"enclosed-colored"} isLazy>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <TabList>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={FiCreditCard} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.payment_method")}</Text>
                  </Hide>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={FiTarget} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.point_card")}</Text>
                  </Hide>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={RiCoupon2Line} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.coupon")}</Text>
                  </Hide>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={BsCardImage} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.kyc_image")}</Text>
                  </Hide>
                </Stack>
              </Tab>
              <Tab>
                <Stack direction={"row"} alignItems="center" spacing={2}>
                  <Show below="md">
                    <Icon as={MdHistory} />
                  </Show>
                  <Hide below="md">
                    <Text>{t("user.tab.history")}</Text>
                  </Hide>
                </Stack>
              </Tab>
            </TabList>
            <Hide below="md">{renderActions()}</Hide>
          </Stack>
          <TabPanels>
            <TabPanel>
              <ListUserPaymentMethod
                payment_methods={data.user_methods.PaymentMethod || []}
              />
            </TabPanel>
            <TabPanel>
              <ListUserPointCard
                point_cards={data.user_methods.PointCard || []}
              />
            </TabPanel>
            <TabPanel>
              <ListUserCoupon coupons={data.user_methods.Coupon || []} />
            </TabPanel>
            <TabPanel>
              <ListImage user={data} />
            </TabPanel>
            <TabPanel>
              <ListUserHistory user_id={data.id} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("user.reject_form.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                reason: "",
              }}
              onSubmit={async (values, { setErrors }) => {
                changeKycStatus("rejected", values.reason);
                onClose();
              }}
            >
              {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <VStack spacing={4} align="flex-start">
                    <FormControl isInvalid={!!errors.reason && touched.reason}>
                      <FormLabel htmlFor="reason">
                        {t("user.reject_form.reason")}
                      </FormLabel>
                      <Field as={Textarea} id="reason" name="reason"></Field>
                      <FormErrorMessage>{errors.reason}</FormErrorMessage>
                    </FormControl>
                    <Stack direction="row" spacing={4} align="center">
                      <Button colorScheme="red" mr={3} type="submit">
                        {t("user.kyc_action.reject")}
                      </Button>
                      <Button onClick={onClose} variant="ghost">
                        {t("action.cancel")}
                      </Button>
                    </Stack>
                  </VStack>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
