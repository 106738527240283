import React from "react";
import { Formik, Field, FieldProps } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import API from "../../config/api";

export default function CreateCoupon() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("coupon.title.new")}
      </Heading>
      <Box bg="white" rounded="md" w={360} pb={4}>
        <Formik
          initialValues={{
            issuer: "",
            name: "",
            discount_money: "0",
            discount_type: "percentage",
            logo: null,
            banner: null,
            url: "",
            ios_app_id: "",
            android_app_id: "",
            ios_url_scheme: "",
            status: "activate",
          }}
          onSubmit={async (values, { setErrors }) => {
            setSubmitting(true);

            var bodyFormData = new FormData();
            Object.entries(values).forEach(([key, val]) => {
              if (key !== "logo" && key !== "banner")
                bodyFormData.append(key, val as string);
            });
            if (values.logo) {
              bodyFormData.append("logo", values.logo);
            }
            if (values.banner) {
              bodyFormData.append("banner", values.banner);
            }

            axios({
              method: "post",
              url: API.COUPON,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(function (response) {
                toast({
                  title: t("coupon.api.created_success"),
                  status: "success",
                  isClosable: true,
                });
                setSubmitting(false);
                navigate(-1);
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          }}
        >
          {({ handleSubmit, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={!!errors.issuer && touched.issuer}
                >
                  <FormLabel htmlFor="issuer">{t("coupon.issuer")}</FormLabel>
                  <Field as={Input} id="issuer" name="issuer" />
                  <FormErrorMessage>{errors.issuer}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={!!errors.name && touched.name}
                >
                  <FormLabel htmlFor="name">{t("coupon.name")}</FormLabel>
                  <Field as={Input} id="name" name="name" />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.url && touched.url}>
                  <FormLabel htmlFor="url">{t("coupon.url")}</FormLabel>
                  <Field as={Input} id="url" name="url" />
                  <FormErrorMessage>{errors.url}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.ios_app_id && touched.ios_app_id}
                >
                  <FormLabel htmlFor="ios_app_id">
                    {t("coupon.ios_app_id")}
                  </FormLabel>
                  <Field as={Input} id="ios_app_id" name="ios_app_id" />
                  <FormErrorMessage>{errors.ios_app_id}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.ios_url_scheme && touched.ios_url_scheme}
                >
                  <FormLabel htmlFor="ios_url_scheme">
                    {t("coupon.ios_url_scheme")}
                  </FormLabel>
                  <Field as={Input} id="ios_url_scheme" name="ios_url_scheme" />
                  <FormErrorMessage>{errors.ios_url_scheme}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.android_app_id && touched.android_app_id}
                >
                  <FormLabel htmlFor="android_app_id">
                    {t("coupon.android_app_id")}
                  </FormLabel>
                  <Field as={Input} id="android_app_id" name="android_app_id" />
                  <FormErrorMessage>{errors.android_app_id}</FormErrorMessage>
                </FormControl>
                <Field name="logo">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl isInvalid={!!errors.logo && touched.logo}>
                        <FormLabel htmlFor="logo">
                          {t("payment_method.logo")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={URL.createObjectURL(field.value)}
                                boxSize="100px"
                                objectFit="cover"
                                alt="Logo"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => setFieldValue(field.name, null)}
                              />
                            </Stack>
                          )}
                          <input
                            name="logo"
                            accept="image/*"
                            id="logo"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              fileReader.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>{errors.logo}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="banner">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl
                        isInvalid={!!errors.banner && touched.banner}
                      >
                        <FormLabel htmlFor="banner">
                          {t("payment_method.banner")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={URL.createObjectURL(field.value)}
                                boxSize="100px"
                                objectFit="cover"
                                alt="banner"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => setFieldValue(field.name, null)}
                              />
                            </Stack>
                          )}
                          <input
                            name="banner"
                            accept="image/*"
                            id="banner"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              fileReader.readAsDataURL(e.target.files[0]);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>{errors.banner}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="discount_money">
                  {({ field, form }: FieldProps) => {
                    return (
                      <FormControl
                        isRequired
                        isInvalid={
                          !!errors.discount_money && touched.discount_money
                        }
                      >
                        <FormLabel htmlFor="discount_money">
                          {t("coupon.discount_money")}
                        </FormLabel>
                        <NumberInput
                          {...field}
                          id="discount_money"
                          name="discount_money"
                          onChange={(val) =>
                            form.setFieldValue(field.name, val)
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                        <FormErrorMessage>
                          {errors.discount_money}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    type="submit"
                    isLoading={submitting}
                    loadingText={t("action.loading.creating")}
                  >
                    {t("action.create")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => navigate(-1)}
                    disabled={submitting}
                  >
                    {t("action.cancel")}
                  </Button>
                </Stack>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}
