import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react";

export default function DataLoading() {
  return (
    <Box padding="6">
      <SkeletonCircle size="10" />
      <SkeletonText mt="4" noOfLines={4} spacing="4" />
    </Box>
  );
}
