import { Routes, Route } from "react-router-dom";
import axios from "axios";
import { setupInterceptorsTo } from "./fetchers/interceptors";

import { AuthProvider } from "./provider/auth";
import { LoginPage } from "./pages/auth";
import SidebarLayout from "./pages/layout";
import PrivateRoute from "./utils/PrivateRoute";

import HomePage from "./pages/home";
import NotFound from "./pages/notFound";
import DashboardPage from "./pages/dashboard";
import {
  ListPaymentMethod,
  CreatePaymentMethod,
  EditPaymentMethod,
  ShowPaymentMethod,
} from "./pages/payment_method";
import {
  ListPointCard,
  CreatePointCard,
  EditPointCard,
  ShowPointCard,
} from "./pages/point_card";
import {
  ListCoupon,
  CreateCoupon,
  EditCoupon,
  ShowCoupon,
} from "./pages/coupon";
import {
  EditUser,
  ListUser,
  ShowUser,
  RegisterMethod as RegisterUserMethod,
  InterestMethod,
} from "./pages/user";
import {
  CreateMerchant,
  EditMerchant,
  ListMerchant,
  RegisterMethod as RegisterMerchantMethod,
  ShowMerchant,
} from "./pages/merchant";
import {
  ListNotification,
  CreateNotification,
  EditNotification,
  ShowNotification,
} from "./pages/notification";
import { CreateTerm, EditTerm, ListTerm, ShowTerm } from "./pages/term";
import { ListRecommendHistory, ShowRecommendHistory } from "./pages/history";
import {
  CreateEventMarketing,
  ListEventMarketing,
  ShowEventMarketing,
} from "./pages/event";

setupInterceptorsTo(axios);

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="login" element={<LoginPage />} />
          <Route
            path="console"
            element={
              <PrivateRoute>
                <SidebarLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="payment_method">
              <Route index element={<ListPaymentMethod />} />
              <Route path="new" element={<CreatePaymentMethod />} />
              <Route path=":id">
                <Route index element={<ShowPaymentMethod />} />
                <Route path="edit" element={<EditPaymentMethod />} />
              </Route>
            </Route>
            <Route path="coupon">
              <Route index element={<ListCoupon />} />
              <Route path="new" element={<CreateCoupon />} />
              <Route path=":id">
                <Route index element={<ShowCoupon />} />
                <Route path="edit" element={<EditCoupon />} />
              </Route>
            </Route>
            <Route path="point_card">
              <Route index element={<ListPointCard />} />
              <Route path="new" element={<CreatePointCard />} />
              <Route path=":id">
                <Route index element={<ShowPointCard />} />
                <Route path="edit" element={<EditPointCard />} />
              </Route>
            </Route>
            <Route path="user">
              <Route index element={<ListUser />} />
              <Route path=":id">
                <Route index element={<ShowUser />} />
                <Route path="edit" element={<EditUser />} />
                <Route path="register" element={<RegisterUserMethod />} />
                <Route path="interest" element={<InterestMethod />} />
              </Route>
            </Route>
            <Route path="merchant">
              <Route index element={<ListMerchant />} />
              <Route path="new" element={<CreateMerchant />} />
              <Route path=":id">
                <Route index element={<ShowMerchant />} />
                <Route path="edit" element={<EditMerchant />} />
                <Route path="register" element={<RegisterMerchantMethod />} />
              </Route>
            </Route>
            <Route path="notification">
              <Route index element={<ListNotification />} />
              <Route path="new" element={<CreateNotification />} />
              <Route path=":id">
                <Route index element={<ShowNotification />} />
                <Route path="edit" element={<EditNotification />} />
              </Route>
            </Route>
            <Route path="term">
              <Route index element={<ListTerm />} />
              <Route path="new" element={<CreateTerm />} />
              <Route path=":id">
                <Route index element={<ShowTerm />} />
                <Route path="edit" element={<EditTerm />} />
              </Route>
            </Route>
            <Route path="recommend_history">
              <Route index element={<ListRecommendHistory />} />
              <Route path=":token">
                <Route index element={<ShowRecommendHistory />} />
              </Route>
            </Route>
            <Route path="event_marketing">
              <Route index element={<ListEventMarketing />} />
              <Route path="new" element={<CreateEventMarketing />} />
              <Route path=":id">
                <Route index element={<ShowEventMarketing />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
}
