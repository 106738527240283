import React from "react";
import {
  Heading,
  Flex,
  Tag,
  TagLabel,
  Link,
  IconButton,
} from "@chakra-ui/react";
import { User } from "../../fetchers/model";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../utils/Table";
import axios from "axios";
import { Select } from "chakra-react-select";
import { FilterValue, SortBy } from "../../utils/CommonInterface";
import API from "../../config/api";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

export default function ListUser() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<User[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      globalFilter,
      filters,
      sortBy,
    }: {
      pageSize: number;
      pageIndex: number;
      globalFilter: string;
      filters: FilterValue[];
      sortBy: SortBy[];
    }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);

      const kycStatusFilters = filters.filter(
        (x) => x.id === "kyc_status" && x.value.length > 0
      );
      let kycStatusParams =
        (kycStatusFilters.length > 0 &&
          kycStatusFilters[0].value
            .map((x) => `&kyc_status[]=${x}`)
            .join("")) ||
        "";

      const genderFilters = filters.filter(
        (x) => x.id === "gender" && x.value.length > 0
      );
      let genderParams =
        (genderFilters.length > 0 &&
          genderFilters[0].value.map((x) => `&gender[]=${x}`).join("")) ||
        "";

      let sortByParam =
        (sortBy.length > 0 &&
          `&order=${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`) ||
        "";
      const { data, headers } = await axios.get<User[]>(
        `${API.USER}?page=${
          pageIndex + 1
        }&per_page=${pageSize}&excepts="doc_back_end,doc_front_end,doc_selfie,user_methods"${
          globalFilter ? `&keyword=${globalFilter}` : ""
        }${genderParams}${kycStatusParams}${sortByParam}`
      );
      if (fetchId === fetchIdRef.current) {
        setData(data);
        setPageCount(Math.ceil(parseInt(headers["total"]) / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const genderOptions = ["male", "female", "other"].map(function (val) {
    return {
      label: t(`user.gender.${val}`),
      value: val,
    };
  });

  const kycStatusOptions = [
    "waiting_review",
    "in_review",
    "approved",
    "rejected",
  ].map(function (val) {
    return {
      label: t(`user.kyc_status.${val}`),
      value: val,
    };
  });

  const tableColumns = [
    {
      Header: t("user.full_name"),
      accessor: "name" as const,
      disableFilters: true,
    },
    {
      Header: t("user.email"),
      accessor: "email" as const,
      disableFilters: true,
    },
    {
      Header: t("user.gender.title"),
      FilterHeader: t("user.gender.title"),
      accessor: "gender" as const,
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={genderOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={genderOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: t("user.address.country"),
      accessor: "country" as const,
      disableFilters: true,
    },
    {
      Header: t("user.kyc_status.title"),
      FilterHeader: t("user.kyc_status.title"),
      accessor: "kyc_status" as const,
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={kycStatusOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={kycStatusOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: "",
      accessor: "action" as const,
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const tableData = data.map((item) => ({
    name: (
      <Link
        color="blue.500"
        href={`/console/user/${item.id}`}
        fontWeight={"bold"}
      >
        {`${item.name || t("user.name_unknown")}`}
      </Link>
    ),
    email: item.email,
    gender: (
      <Tag
        size={"sm"}
        borderRadius="full"
        variant="solid"
        colorScheme={
          item.gender === "male"
            ? "blue"
            : item.gender === "female"
            ? "pink"
            : "gray"
        }
      >
        <TagLabel>{t(`user.gender.${item.gender}`)}</TagLabel>
      </Tag>
    ),
    country: item.country,
    kyc_status: (
      <Tag
        size={"sm"}
        borderRadius="full"
        variant="solid"
        colorScheme={
          item.kyc_status === "in_review"
            ? "yellow"
            : item.kyc_status === "approved"
            ? "blue"
            : item.kyc_status === "rejected"
            ? "red"
            : item.kyc_status === "waiting_review"
            ? "pink"
            : "gray"
        }
      >
        <TagLabel>{t(`user.kyc_status.${item.kyc_status}`)}</TagLabel>
      </Tag>
    ),
    action: (
      <IconButton
        icon={<FiEdit2 />}
        aria-label={t("action.edit")}
        variant="link"
        onClick={() => navigate(`/console/user/${item.id}/edit`)}
      />
    ),
  }));

  return (
    <>
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" mb="4">
          {t("user.title.name")}
        </Heading>
      </Flex>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        useSearch={true}
        disableFilters={false}
        disableSortBy={false}
      />
    </>
  );
}
