import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
} from "@chakra-ui/react";
import {
  FiUsers,
  FiBell,
  FiCreditCard,
  FiTarget,
  FiMenu,
} from "react-icons/fi";
import { AiOutlineDashboard } from "react-icons/ai";
import { BiStore, BiLogOut } from "react-icons/bi";
import { RiCoupon2Line } from "react-icons/ri";
import { MdHistory, MdOutlineEventAvailable } from "react-icons/md";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaSignature } from "react-icons/fa";
import { useAuth } from "../provider/auth";

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "menu.dashboard", icon: AiOutlineDashboard, href: "/" },
  {
    name: "menu.history",
    icon: MdHistory,
    href: "/recommend_history",
  },
  {
    name: "menu.event",
    icon: MdOutlineEventAvailable,
    href: "/event_marketing",
  },
  { name: "menu.user", icon: FiUsers, href: "/user" },
  { name: "menu.merchant", icon: BiStore, href: "/merchant" },
  { name: "menu.payment_method", icon: FiCreditCard, href: "/payment_method" },
  { name: "menu.coupon", icon: RiCoupon2Line, href: "/coupon" },
  { name: "menu.point_card", icon: FiTarget, href: "/point_card" },
  { name: "menu.notification", icon: FiBell, href: "/notification" },
  { name: "menu.term", icon: FaSignature, href: "/term" },
];

export default function SidebarLayout() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={"white"}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Outlet />
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  return (
    <Box
      bg={"blue.600"}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
          color={"white"}
        >
          {t("appName")}
        </Text>
        <CloseButton
          color={"white"}
          display={{ base: "flex", md: "none" }}
          onClick={onClose}
        />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          href={link.href}
          color={"white"}
          fontWeight="semibold"
        >
          {t(link.name)}
        </NavItem>
      ))}
      <NavItem
        icon={BiLogOut}
        href={"#"}
        onClick={() => auth.signOut()}
        color={"white"}
        fontWeight="semibold"
      >
        {t("action.signOut")}
      </NavItem>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  href: string;
  children: ReactText;
}
const NavItem = ({ icon, href, children, ...rest }: NavItemProps) => {
  return (
    <Link
      href={`/console${href}`}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="3"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "blue.400",
        }}
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="16" color={"white"} as={icon} />}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        {t("appName")}
      </Text>
    </Flex>
  );
};
