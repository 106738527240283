import React from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Divider,
  Link,
  SimpleGrid,
  Image,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EventMarketing } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import axios from "axios";
import API from "../../config/api";
import { ExtractTimeToString } from "../../utils/Date";

export default function ShowEventMarketing() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<EventMarketing>();
  const { t } = useTranslation();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<EventMarketing>(
      `${API.EVENT}/${params.id}`
    );
    setData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  return (
    <Flex h="100vh" direction={"column"}>
      <Flex justifyContent="space-between" alignItems={"center"}>
        <Box>
          <Link
            color="blue.500"
            href={`/console/event_marketing`}
            fontWeight={"semibold"}
          >
            {t("action.backToList")}
          </Link>
          <Heading as="h4" size="lg" mb="4">
            {`${data.title}`}
          </Heading>
        </Box>
      </Flex>
      <Box bg="white">
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("event.start_at")}</Text>
              <Text fontWeight={"semibold"}>
                {data.start_at
                  ? new Date(data.start_at)
                      .toISOString()
                      .slice(0, 19)
                      .replace(/-/g, "/")
                      .replace("T", " ")
                  : "-"}
              </Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("event.end_at")}</Text>
              <Text fontWeight={"semibold"}>
                {data.end_at
                  ? new Date(data.end_at)
                      .toISOString()
                      .slice(0, 19)
                      .replace(/-/g, "/")
                      .replace("T", " ")
                  : "-"}
              </Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("event.scheduled_send_time")}</Text>
              <Text fontWeight={"semibold"}>
                {new Date(data.scheduled_send_time)
                  .toISOString()
                  .slice(0, 19)
                  .replace(/-/g, "/")
                  .replace("T", " ")}
              </Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("event.apply_time")}</Text>
              <Text fontWeight={"semibold"}>
                {data.apply_time
                  ? ExtractTimeToString(new Date(data.apply_time))
                  : "-"}
              </Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("event.delivered_at")}</Text>
              <Text fontWeight={"semibold"}>
                {data.delivered_at
                  ? new Date(data.delivered_at)
                      .toISOString()
                      .slice(0, 19)
                      .replace(/-/g, "/")
                      .replace("T", " ")
                  : "-"}
              </Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("event.delivered_status.title")}</Text>
              <Box>
                <Tag
                  borderRadius="full"
                  variant="solid"
                  colorScheme={data.delivered_at ? "blue" : "gray"}
                  justifyContent="center"
                >
                  <TagLabel>
                    {t(
                      `event.delivered_status.${
                        data.delivered_at ? "delivered" : "not_deliver"
                      }`
                    )}
                  </TagLabel>
                </Tag>
              </Box>
            </Box>
          </Stack>
        </SimpleGrid>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("event.merchant")}</Text>
              <Text fontWeight={"semibold"}>{data.merchant.company_name}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("event.event_type.title")}</Text>
              <Text fontWeight={"semibold"}>
                {t(`event.event_type.${data.event_type}`)}
              </Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("event.discount_money")}</Text>
              <Text fontWeight={"semibold"}>{data.discount_money}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("event.broadcast_radius")}</Text>
              <Text fontWeight={"semibold"}>{data.broadcast_radius}</Text>
            </Box>
          </Stack>
          <Stack direction={"column"} spacing={2}>
            <Box>
              <Text fontSize={"sm"}>{t("event.reference")}</Text>
              <Text fontWeight={"semibold"}>{data.reference || "-"}</Text>
            </Box>
            <Box>
              <Text fontSize={"sm"}>{t("event.receiver_count")}</Text>
              <Text fontWeight={"semibold"}>{data.receiver_count}</Text>
            </Box>
          </Stack>
        </SimpleGrid>
        <Divider mt={4} mb={4} />
        <Box>
          {data.thumbnails?.id && (
            <Image
              src={data.thumbnails.url}
              maxHeight="200px"
              objectFit="cover"
              alt="Logo"
              mb={2}
            />
          )}
          <Text>{data.description}</Text>
        </Box>
      </Box>
    </Flex>
  );
}
