import React from "react";
import { Formik, Field, FieldProps } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Stack,
  Radio,
  RadioGroup,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { User } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import axios from "axios";
import API from "../../config/api";

export default function EditUser() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<User>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<User>(`${API.USER}/${params.id}`);
    setData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("user.title.edit", { email: data.email })}
      </Heading>
      <Box bg="white" rounded="md" w={360} pb={4}>
        <Formik
          initialValues={{
            first_name: data.first_name,
            last_name: data.last_name,
            gender: data.gender,
            phone: data.phone,
            postal_code: data.postal_code,
            country: data.country,
            province: data.province,
            district: data.district,
            address_1: data.address_1,
            address_2: data.address_2,
            birth_date: data.birth_date,
            doc_front_end: data.doc_front_end?.url,
            doc_back_end: data.doc_back_end?.url,
            doc_selfie: data.doc_selfie?.url,
            isDocFrontEndChange: false,
            isDocBackEndChange: false,
            isDocSelfieChange: false,
          }}
          onSubmit={async (values, { setErrors }) => {
            setSubmitting(true);

            var bodyFormData = new FormData();
            Object.entries(values).forEach(([key, val]) => {
              if (
                ![
                  "doc_front_end",
                  "doc_back_end",
                  "doc_selfie",
                  "isDocFrontEndChange",
                  "isDocBackEndChange",
                  "isDocSelfieChange",
                ].includes(key)
              )
                bodyFormData.append(key, val as string);
            });
            if (values.isDocFrontEndChange) {
              if (values.doc_front_end)
                bodyFormData.append("doc_front_end", values.doc_front_end);
              data &&
                data.doc_front_end &&
                bodyFormData.append(
                  "destroy_upload_ids[]",
                  data.doc_front_end?.id
                );
            }
            if (values.isDocBackEndChange) {
              if (values.doc_back_end)
                bodyFormData.append("doc_back_end", values.doc_back_end);
              data &&
                data.doc_back_end &&
                bodyFormData.append(
                  "destroy_upload_ids[]",
                  data.doc_back_end?.id
                );
            }
            if (values.isDocSelfieChange) {
              if (values.doc_selfie)
                bodyFormData.append("doc_selfie", values.doc_selfie);
              data &&
                data.doc_selfie &&
                bodyFormData.append(
                  "destroy_upload_ids[]",
                  data.doc_selfie?.id
                );
            }

            axios({
              method: "put",
              url: `${API.USER}/${params.id}`,
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
            })
              .then(function (response) {
                toast({
                  title: t("user.api.updated_success"),
                  status: "success",
                  isClosable: true,
                });
                setSubmitting(false);
                navigate(-1);
              })
              .catch(function (response) {
                //handle error
                console.log(response);
              });
          }}
        >
          {({ handleSubmit, setFieldValue, values, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={!!errors.first_name && touched.first_name}
                >
                  <FormLabel htmlFor="first_name">
                    {t("user.first_name")}
                  </FormLabel>
                  <Field as={Input} id="first_name" name="first_name" />
                  <FormErrorMessage>{errors.first_name}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={!!errors.last_name && touched.last_name}
                >
                  <FormLabel htmlFor="last_name">
                    {t("user.last_name")}
                  </FormLabel>
                  <Field as={Input} id="last_name" name="last_name" />
                  <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                </FormControl>
                <Field name="gender">
                  {({ field, form }: FieldProps) => {
                    const { onChange, ...rest } = field;
                    return (
                      <FormControl
                        isRequired
                        isInvalid={
                          !!form.errors.payment_type &&
                          !!form.touched.payment_type
                        }
                      >
                        <FormLabel htmlFor="gender">
                          {t("user.gender.title")}
                        </FormLabel>
                        <RadioGroup
                          {...rest}
                          id="gender"
                          name="gender"
                          colorScheme="blue"
                        >
                          <Stack spacing={5} direction="row">
                            {["male", "female", "other"].map((x) => (
                              <Radio key={x} onChange={onChange} value={x}>
                                {t(`user.gender.${x}`)}
                              </Radio>
                            ))}
                          </Stack>
                        </RadioGroup>
                      </FormControl>
                    );
                  }}
                </Field>
                <FormControl
                  isInvalid={!!errors.birth_date && touched.birth_date}
                >
                  <FormLabel htmlFor="birth_date">
                    {t("user.birth_date")}
                  </FormLabel>
                  <Field
                    as={Input}
                    type="date"
                    id="birth_date"
                    name="birth_date"
                  />
                  <FormErrorMessage>{errors.birth_date}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.phone && touched.phone}>
                  <FormLabel htmlFor="phone">{t("user.phone")}</FormLabel>
                  <Field as={Input} id="phone" name="phone" />
                  <FormErrorMessage>{errors.phone}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.postal_code && touched.postal_code}
                >
                  <FormLabel htmlFor="postal_code">
                    {t("user.address.postal_code")}
                  </FormLabel>
                  <Field as={Input} id="postal_code" name="postal_code" />
                  <FormErrorMessage>{errors.postal_code}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.country && touched.country}>
                  <FormLabel htmlFor="country">
                    {t("user.address.country")}
                  </FormLabel>
                  <Field as={Input} id="country" name="country" />
                  <FormErrorMessage>{errors.country}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.province && touched.province}>
                  <FormLabel htmlFor="province">
                    {t("user.address.province")}
                  </FormLabel>
                  <Field as={Input} id="province" name="province" />
                  <FormErrorMessage>{errors.province}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.district && touched.district}>
                  <FormLabel htmlFor="district">
                    {t("user.address.district")}
                  </FormLabel>
                  <Field as={Input} id="district" name="district" />
                  <FormErrorMessage>{errors.district}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.address_1 && touched.address_1}
                >
                  <FormLabel htmlFor="address_1">
                    {t("user.address.address_1")}
                  </FormLabel>
                  <Field as={Input} id="address_1" name="address_1" />
                  <FormErrorMessage>{errors.address_1}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.address_2 && touched.address_2}
                >
                  <FormLabel htmlFor="address_2">
                    {t("user.address.address_2")}
                  </FormLabel>
                  <Field as={Input} id="address_2" name="address_2" />
                  <FormErrorMessage>{errors.address_2}</FormErrorMessage>
                </FormControl>
                <Field name="doc_front_end">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl
                        isInvalid={
                          !!errors.doc_front_end && touched.doc_front_end
                        }
                      >
                        <FormLabel htmlFor="doc_front_end">
                          {t("user.doc_front_end")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={
                                  values.isDocFrontEndChange
                                    ? URL.createObjectURL(field.value)
                                    : field.value
                                }
                                boxSize="100px"
                                objectFit="cover"
                                alt="Logo"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => {
                                  setFieldValue("isDocFrontEndChange", true);
                                  setFieldValue(field.name, null);
                                }}
                              />
                            </Stack>
                          )}
                          <input
                            name="doc_front_end"
                            accept="image/*"
                            id="doc_front_end"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              fileReader.readAsDataURL(e.target.files[0]);
                              setFieldValue("isDocFrontEndChange", true);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>
                          {errors.doc_front_end}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="doc_back_end">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl
                        isInvalid={
                          !!errors.doc_back_end && touched.doc_back_end
                        }
                      >
                        <FormLabel htmlFor="doc_back_end">
                          {t("user.doc_back_end")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={
                                  values.isDocBackEndChange
                                    ? URL.createObjectURL(field.value)
                                    : field.value
                                }
                                boxSize="100px"
                                objectFit="cover"
                                alt="Logo"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => {
                                  setFieldValue("isDocBackEndChange", true);
                                  setFieldValue(field.name, null);
                                }}
                              />
                            </Stack>
                          )}
                          <input
                            name="doc_back_end"
                            accept="image/*"
                            id="doc_back_end"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              fileReader.readAsDataURL(e.target.files[0]);
                              setFieldValue("isDocBackEndChange", true);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>
                          {errors.doc_back_end}
                        </FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Field name="doc_selfie">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl
                        isInvalid={!!errors.doc_selfie && touched.doc_selfie}
                      >
                        <FormLabel htmlFor="doc_selfie">
                          {t("user.doc_selfie")}
                        </FormLabel>
                        <Box
                          display="flex"
                          textAlign="center"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          {field.value && (
                            <Stack direction={"row"} spacing={1}>
                              <Image
                                src={
                                  values.isDocSelfieChange
                                    ? URL.createObjectURL(field.value)
                                    : field.value
                                }
                                boxSize="100px"
                                objectFit="cover"
                                alt="Logo"
                                mb={2}
                              />
                              <CloseButton
                                onClick={() => {
                                  setFieldValue("isDocSelfieChange", true);
                                  setFieldValue(field.name, null);
                                }}
                              />
                            </Stack>
                          )}
                          <input
                            name="doc_selfie"
                            accept="image/*"
                            id="doc_selfie"
                            type="file"
                            onChange={(e) => {
                              const fileReader = new FileReader();
                              fileReader.onloadend = () => {
                                if (fileReader.result instanceof ArrayBuffer) {
                                  setFieldValue(field.name, null);
                                  return;
                                }
                                if (
                                  e.target.files &&
                                  e.target.files.length === 1
                                ) {
                                  setFieldValue(field.name, e.target.files[0]);
                                }
                              };
                              if (
                                !e.target.files ||
                                e.target.files.length !== 1
                              ) {
                                setFieldValue(field.name, null);
                                return;
                              }
                              fileReader.readAsDataURL(e.target.files[0]);
                              setFieldValue("isDocSelfieChange", true);
                            }}
                          />
                        </Box>
                        <FormErrorMessage>{errors.doc_selfie}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    type="submit"
                    isLoading={submitting}
                    loadingText={t("action.loading.updating")}
                  >
                    {t("action.update")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => navigate(-1)}
                    disabled={submitting}
                  >
                    {t("action.cancel")}
                  </Button>
                </Stack>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}
