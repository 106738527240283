import React from "react";
import {
  Heading,
  Flex,
  Tag,
  TagLabel,
  Link,
  Stack,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { Term } from "../../fetchers/model";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../utils/Table";
import { FiEdit2, FiPlus } from "react-icons/fi";
import axios from "axios";
import { Select } from "chakra-react-select";
import { FilterValue, SortBy } from "../../utils/CommonInterface";
import { ConvertDateToString } from "../../utils/Date";
import API from "../../config/api";

export default function ListTerm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Term[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      globalFilter,
      filters,
      sortBy,
    }: {
      pageSize: number;
      pageIndex: number;
      globalFilter: string;
      filters: FilterValue[];
      sortBy: SortBy[];
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      const statusFilters = filters.filter(
        (x) => x.id === "status" && x.value.length > 0
      );
      let statusParams =
        (statusFilters.length > 0 &&
          statusFilters[0].value.map((x) => `&status[]=${x}`).join("")) ||
        "";

      let sortByParam =
        (sortBy.length > 0 &&
          `&order=${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`) ||
        "";
      const { data, headers } = await axios.get<Term[]>(
        `${API.TERM}?page=${pageIndex + 1}&per_page=${pageSize}${
          globalFilter ? `&keyword=${globalFilter}` : ""
        }${statusParams}${sortByParam}`
      );
      if (fetchId === fetchIdRef.current) {
        setData(data);
        setPageCount(Math.ceil(parseInt(headers["total"]) / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const statusOptions = ["published", "draft"].map(function (val) {
    return {
      label: t(`term.status.${val}`),
      value: val,
    };
  });

  const tableColumns = [
    {
      Header: t("term.version_no"),
      accessor: "version_no" as const,
      disableFilters: true,
    },
    {
      Header: t("term.term_no"),
      accessor: "term_no" as const,
      disableFilters: true,
    },
    {
      Header: t("term.status.title"),
      FilterHeader: t("term.status.title"),
      accessor: "status" as const,
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={statusOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={statusOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: t("term.publish_at"),
      accessor: "publish_at" as const,
      disableFilters: true,
    },
    {
      Header: t("term.force_read.title"),
      accessor: "force_read" as const,
      disableFilters: true,
    },
    {
      Header: "",
      accessor: "action" as const,
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const tableData = data.map((item) => ({
    version_no: (
      <Link
        color="blue.500"
        href={`/console/term/${item.id}`}
        fontWeight={"bold"}
      >
        {`#${item.version_no}`}
      </Link>
    ),
    term_no: item.term_no,
    status: (
      <Tag
        size={"sm"}
        borderRadius="full"
        variant={item.status === "published" ? "solid" : "outline"}
        colorScheme="blue"
      >
        <TagLabel>{t(`term.status.${item.status}`)}</TagLabel>
      </Tag>
    ),
    publish_at:
      item.publish_at && ConvertDateToString(new Date(item.publish_at)),
    force_read: item.force_read && <CheckIcon />,
    action: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <IconButton
          icon={<FiEdit2 />}
          aria-label={t("action.edit")}
          variant="link"
          onClick={() => navigate(`/console/term/${item.id}/edit`)}
        />
      </Stack>
    ),
  }));

  return (
    <>
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" mb="4">
          {t("term.title.name")}
        </Heading>
        <Button
          leftIcon={<FiPlus />}
          colorScheme="blue"
          variant="solid"
          onClick={() => navigate("/console/term/new")}
        >
          {t("action.new")}
        </Button>
      </Flex>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        useSearch={true}
        disableFilters={false}
        disableSortBy={false}
      />
    </>
  );
}
