import React from "react";
import { Box, Flex, Heading, Input, SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Merchant } from "../../fetchers/model";
import VisitorChart from "./visitor";
import RevenueChart from "./revenue";
import { Select } from "chakra-react-select";
import { SelectOptions } from "../../utils/CommonInterface";
import PopularChart from "./popular";
import axios from "axios";
import API from "../../config/api";
import { ConvertDateToString } from "../../utils/Date";

export default function DashboardPage() {
  const today = new Date();
  const [merchants, setMerchants] = React.useState<SelectOptions[]>([]);
  const [selectedMerchants, setSelectedMerchants] = React.useState<string[]>(
    []
  );
  const [dateFrom, setDateFrom] = React.useState<Date>(
    new Date(Date.now() - 24 * 24 * 3600 * 1000)
  );
  const [dateTo, setDateTo] = React.useState<Date>(today);
  const [bySerie, setBySerie] = React.useState<string>("day");
  const { t } = useTranslation();

  const fetchMerchant = async () => {
    const { data: result } = await axios.get<Merchant[]>(API.MERCHANT);
    setMerchants(
      result.map(function (x) {
        return {
          value: x.id,
          label: x.company_name,
        };
      })
    );
  };

  React.useEffect(() => {
    fetchMerchant();
  }, []);

  const byOptions = ["day", "month", "year"].map(function (val) {
    return {
      label: t(`dashboard.filter.by.${val}`),
      value: val,
    };
  });

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("dashboard.title")}
      </Heading>
      <SimpleGrid columns={{ md: 4, sm: 2 }} spacing={6}>
        <Box>
          <Box mb={2}>{t("dashboard.filter.merchant")}</Box>
          <Select
            size="sm"
            name="merchants"
            isClearable
            value={merchants.filter(
              (x) => selectedMerchants && selectedMerchants.includes(x.value)
            )}
            options={merchants}
            onChange={(option) =>
              setSelectedMerchants(
                (option && option.value && [option.value]) || []
              )
            }
          />
        </Box>
        <Box>
          <Box mb={2}>{t("dashboard.filter.date_from")}</Box>
          <Input
            size="sm"
            type="date"
            id="from_date"
            name="from_date"
            value={(dateFrom && ConvertDateToString(new Date(dateFrom))) || ""}
            onChange={(e) => setDateFrom(new Date(e.target.value))}
          />
        </Box>
        <Box>
          <Box mb={2}>{t("dashboard.filter.date_to")}</Box>
          <Input
            size="sm"
            type="date"
            id="to_date"
            name="to_date"
            value={(dateTo && ConvertDateToString(new Date(dateTo))) || ""}
            onChange={(e) => setDateTo(new Date(e.target.value))}
          />
        </Box>
        <Box>
          <Box mb={2}>{t("dashboard.filter.by.title")}</Box>
          <Select
            size="sm"
            name="bySerie"
            value={byOptions.filter((x) => bySerie === x.value)}
            options={byOptions}
            onChange={(option) => setBySerie(option?.value || "day")}
          />
        </Box>
      </SimpleGrid>
      {/* <Box mt={4}>
        <Button colorScheme={"blue"} variant="solid" size={"sm"}>
          {t("action.submit")}
        </Button>
      </Box> */}
      <SimpleGrid columns={{ sm: 1, md: 2 }} gap={8}>
        <Box>
          <VisitorChart
            merchants={selectedMerchants}
            dateFrom={dateFrom}
            dateTo={dateTo}
            bySerie={bySerie}
          />
        </Box>
        <Box>
          <RevenueChart
            merchants={selectedMerchants}
            dateFrom={dateFrom}
            dateTo={dateTo}
            bySerie={bySerie}
          />
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={{ sm: 2, md: 3 }} gap={8} mt={4}>
        <Box>
          <PopularChart
            merchants={selectedMerchants}
            dateFrom={dateFrom}
            dateTo={dateTo}
            bySerie={bySerie}
            payment_type="Coupon"
          />
        </Box>
        <Box>
          <PopularChart
            merchants={selectedMerchants}
            dateFrom={dateFrom}
            dateTo={dateTo}
            bySerie={bySerie}
            payment_type="PointCard"
          />
        </Box>
        <Box>
          <PopularChart
            merchants={selectedMerchants}
            dateFrom={dateFrom}
            dateTo={dateTo}
            bySerie={bySerie}
            payment_type="PaymentMethod"
          />
        </Box>
      </SimpleGrid>
    </Flex>
  );
}
