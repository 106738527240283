import React from "react";
import { Box, Stack, Link, Image, Icon } from "@chakra-ui/react";
import { RiQrCodeLine } from "react-icons/ri";
import { BsCreditCard2Back, BsCardImage } from "react-icons/bs";
import { PaymentMethod, MerchantMethod } from "../../../fetchers/model";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../../utils/Table";

export default function ListMerchantPaymentMethod({
  payment_methods,
}: {
  payment_methods: MerchantMethod<PaymentMethod>[];
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<MerchantMethod<PaymentMethod>[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
    }: {
      pageSize: number;
      pageIndex: number;
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setData(payment_methods.slice(startRow, endRow));
        setPageCount(Math.ceil(payment_methods.length / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const tableColumns = [
    {
      Header: t("payment_method.name"),
      accessor: "name" as const,
    },
    {
      Header: t("payment_method.publisher"),
      accessor: "publisher" as const,
    },
    {
      Header: t("payment_method.refund_money"),
      accessor: "refund_money" as const,
    },
    {
      Header: t("payment_method.url"),
      accessor: "url" as const,
    },
  ];

  const tableData = data.map((item) => ({
    name: (
      <Stack direction="row" spacing={2} alignItems="center">
        {item.method.logo && item.method.logo.id ? (
          <Image
            boxSize="36px"
            objectFit="cover"
            src={item.method.logo.url}
            alt={item.method.name}
          />
        ) : (
          <Icon as={BsCardImage} boxSize="36px" color={"gray.400"} />
        )}
        <Link
          color="blue.500"
          href={`/console/payment_method/${item.method.id}`}
          fontWeight={"bold"}
        >
          {item.method.name}
        </Link>
      </Stack>
    ),
    publisher: (
      <Stack direction={"row"} spacing={2} alignItems={"center"}>
        {item.method.payment_type === "credit_card" ? (
          <BsCreditCard2Back color="blue" />
        ) : (
          <RiQrCodeLine color="blue" />
        )}
        <Box>{item.method.publisher}</Box>
      </Stack>
    ),
    refund_money:
      item.method.refund_type === "percentage"
        ? `${item.method.refund_money * 100}%`
        : item.method.refund_money,
    url: item.method.url,
  }));

  return (
    <>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
    </>
  );
}
