import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  FormErrorMessage,
} from "@chakra-ui/react";
import * as React from "react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Formik, Field } from "formik";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/auth";
import { useTranslation } from "react-i18next";
import { Logo } from "../../utils/Logo";

export const LoginPage = () => {
  const navigate = useNavigate();
  let auth = useAuth();
  const { t } = useTranslation();

  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  };

  return (
    <Container
      maxW="lg"
      py={{ base: "12", md: "24" }}
      px={{ base: "0", sm: "8" }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo color="blue.500" />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
              Log in to your account
            </Heading>
            {/* <HStack spacing="1" justify="center">
            <Text color="muted">Don't have an account?</Text>
            <Button variant="link" colorScheme="blue">
              Sign up
            </Button>
          </HStack> */}
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={useBreakpointValue({ base: "transparent", sm: "bg-surface" })}
          boxShadow={{ base: "none", sm: useColorModeValue("md", "md-dark") }}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Stack spacing="6">
            <Formik
              initialValues={{
                email: "",
                password: "",
                rememberMe: true,
              }}
              onSubmit={async (values, { setErrors }) => {
                let { email, password, rememberMe } = values;
                await auth
                  .login(email, password, rememberMe)
                  .then(() => {
                    navigate("/console", { replace: true });
                  })
                  .catch((error) => {
                    console.log(error);
                    setErrors({ email: t("login.api.wrong_cred") });
                  });
              }}
            >
              {({ handleSubmit, errors, touched, values, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <Stack spacing="5">
                    <FormControl isInvalid={!!errors.email && touched.email}>
                      <FormLabel htmlFor="email">{t("login.email")}</FormLabel>
                      <Field
                        as={Input}
                        id="email"
                        name="email"
                        type="email"
                        variant="filled"
                        validate={(value: string) => {
                          let error = /^\S+@\S+$/.test(value)
                            ? null
                            : t("login.validate.invalid_email");

                          return error;
                        }}
                      />
                      <FormErrorMessage>{errors.email}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!errors.password && touched.password}
                    >
                      <FormLabel htmlFor="password">
                        {t("login.password")}
                      </FormLabel>
                      <InputGroup>
                        <InputRightElement>
                          <IconButton
                            variant="link"
                            aria-label={
                              isOpen ? "Mask password" : "Reveal password"
                            }
                            icon={isOpen ? <HiEyeOff /> : <HiEye />}
                            onClick={onClickReveal}
                          />
                        </InputRightElement>
                        <Input
                          ref={inputRef}
                          id="password"
                          name="password"
                          variant="filled"
                          onChange={handleChange}
                          value={values.password}
                          type={isOpen ? "text" : "password"}
                          autoComplete="current-password"
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors.password}</FormErrorMessage>
                    </FormControl>
                    <HStack justify="space-between">
                      <Checkbox
                        id="rememberMe"
                        name="rememberMe"
                        onChange={handleChange}
                        isChecked={values.rememberMe}
                        colorScheme="blue"
                      >
                        Remember me?
                      </Checkbox>
                      {/* <Button variant="link" colorScheme="blue" size="sm">
                        Forgot password?
                      </Button> */}
                    </HStack>
                    <Stack spacing="6">
                      <Button colorScheme="blue" type="submit">
                        {t("action.signIn")}
                      </Button>
                      {/* <HStack>
                        <Divider />
                        <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                          or continue with
                        </Text>
                        <Divider />
                      </HStack> */}
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
