import React from "react";
import {
  useTable,
  useFilters,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Select,
  chakra,
  NumberInput,
  NumberInputStepper,
  NumberInputField,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Button,
  TableContainer,
  Text,
  Input,
  Center,
  SimpleGrid,
  Flex,
  Hide,
} from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@chakra-ui/icons";

export function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  useSearch: useSearch = false,
  disableFilters: controlledDisableFilters = true,
  disableSortBy: controlledDisableSortBy = true,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters, globalFilter, sortBy },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        globalFilter: "",
      },
      manualPagination: true,
      manualGlobalFilter: true,
      manualFilters: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      disableFilters: controlledDisableFilters,
      disableSortBy: controlledDisableSortBy,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // setFilter("gender", "male");
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, globalFilter, filters, sortBy });
  }, [fetchData, pageIndex, pageSize, filters, globalFilter, sortBy]);

  return (
    <>
      <Box mb={4}>
        <SimpleGrid columns={{ md: 3, sm: 2 }} spacing={6}>
          {headerGroups.map((headerGroup) =>
            headerGroup.headers.map(
              (column) =>
                column.canFilter && (
                  <Box key={column.id}>
                    <Box mb={2}>{column.render("FilterHeader")}</Box>
                    {column.render("Filter")}
                  </Box>
                )
            )
          )}
        </SimpleGrid>
      </Box>
      <Flex
        mb={3}
        direction={{ base: "column-reverse", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justifyContent="space-between"
        className="global_filter"
      >
        <Stack direction={"row"} spacing={1} alignItems="center">
          <Text>Show</Text>
          <Select
            size={"sm"}
            w={"100px"}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
          <Text>entries</Text>
        </Stack>
        <Box mb={{ base: 2, md: 0 }}>
          {useSearch && (
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          )}
        </Box>
      </Flex>
      <TableContainer boxShadow="sm">
        <ChakraTable {...getTableProps()} size="sm">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <chakra.span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
              );
            })}
            <Tr>
              {loading ? (
                <Td colSpan="10000">Loading...</Td>
              ) : (
                <Td colSpan="10000">
                  Showing {page.length} of ~{controlledPageCount * pageSize}
                  results
                </Td>
              )}
            </Tr>
          </Tbody>
        </ChakraTable>
      </TableContainer>
      {controlledPageCount > 0 && (
        <Flex
          mt={2}
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "flex-start", md: "center" }}
          justifyContent={"flex-end"}
          className="pagination"
        >
          <Stack direction={"row"} spacing={4} alignItems="center">
            <Button
              size={"sm"}
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              variant="ghost"
            >
              <ArrowLeftIcon w={2} h={2} />
            </Button>
            <Button
              size={"sm"}
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              variant="ghost"
            >
              <ChevronLeftIcon w={2} h={2} />
            </Button>
            <Button
              size={"sm"}
              onClick={() => nextPage()}
              disabled={!canNextPage}
              variant="ghost"
            >
              <ChevronRightIcon w={2} h={2} />
            </Button>
            <Button
              size={"sm"}
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              variant="ghost"
            >
              <ArrowRightIcon w={2} h={2} />
            </Button>
            <Center>
              Page
              <strong style={{ marginLeft: "3px" }}>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </Center>
            <Hide below="md">
              <Center>{` | `}</Center>
            </Hide>
          </Stack>
          <Stack direction={"row"} spacing={4} alignItems="center">
            <Center ml={{ base: 0, md: 4 }}>Go to page: </Center>
            <NumberInput
              w={"120px"}
              size={"sm"}
              value={pageIndex + 1}
              min={1}
              max={pageOptions.length}
              onChange={(value) => {
                gotoPage(value - 1);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Stack>
        </Flex>
      )}
    </>
  );
}

function GlobalFilter({ globalFilter, setGlobalFilter }) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Stack direction={"row"} spacing={2} alignItems="center">
      <Text>Search</Text>
      <Input
        size={"sm"}
        variant={"outline"}
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="type to search..."
      />
    </Stack>
  );
}
