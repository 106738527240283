import React from "react";
import { Formik, Field, FieldProps } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Stack,
  Textarea,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { Notification } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import axios from "axios";
import API from "../../config/api";

export default function EditNotification() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Notification>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<Notification>(
      `${API.NOTIFICATION}/${params.id}`
    );
    setData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("notification.title.edit")}
      </Heading>
      <Box bg="white" rounded="md" w={360}>
        <Formik
          initialValues={{
            recipients: data.recipients,
            title: data.title,
            description: data.description,
            reference: data.reference,
            scheduled_send_time: data.scheduled_send_time,
            force_send: false,
          }}
          onSubmit={async (values, { setErrors }) => {
            setSubmitting(true);

            await axios.patch<void>(`${API.NOTIFICATION}/${params.id}`, values);
            toast({
              title: t("notification.api.updated_success"),
              status: "success",
              isClosable: true,
            });
            setSubmitting(false);
            navigate(-1);
          }}
        >
          {({ handleSubmit, setFieldValue, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={!!errors.title && touched.title}
                >
                  <FormLabel htmlFor="title">
                    {t("notification.title.col_name")}
                  </FormLabel>
                  <Field as={Input} id="title" name="title" />
                  <FormErrorMessage>{errors.title}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.description && touched.description}
                >
                  <FormLabel htmlFor="description">
                    {t("notification.description")}
                  </FormLabel>
                  <Field
                    as={Textarea}
                    id="description"
                    name="description"
                  ></Field>
                  <FormErrorMessage>{errors.description}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.reference && touched.reference}
                >
                  <FormLabel htmlFor="reference">
                    {t("notification.reference")}
                  </FormLabel>
                  <Field as={Input} id="reference" name="reference" />
                  <FormErrorMessage>{errors.reference}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={
                    !!errors.scheduled_send_time && touched.scheduled_send_time
                  }
                >
                  <FormLabel htmlFor="scheduled_send_time">
                    {t("notification.scheduled_send_time")}
                  </FormLabel>
                  <Field
                    as={Input}
                    type="datetime-local"
                    id="scheduled_send_time"
                    name="scheduled_send_time"
                  />
                  <FormErrorMessage>
                    {errors.scheduled_send_time}
                  </FormErrorMessage>
                </FormControl>
                <Field name="force_send">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl>
                        <Checkbox
                          id="force_send"
                          name="force_send"
                          colorScheme="blue"
                          isChecked={field.value}
                          onChange={() =>
                            setFieldValue(field.name, !field.value)
                          }
                        >
                          {t("notification.force_send")}
                        </Checkbox>
                      </FormControl>
                    );
                  }}
                </Field>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    type="submit"
                    isLoading={submitting}
                    loadingText={t("action.loading.updating")}
                  >
                    {t("action.update")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => navigate(-1)}
                    disabled={submitting}
                  >
                    {t("action.cancel")}
                  </Button>
                </Stack>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}
