import React from "react";
import { Stack, Icon, Text, Link } from "@chakra-ui/react";
import { FiCreditCard, FiTarget } from "react-icons/fi";
import { RiCoupon2Line } from "react-icons/ri";
import {
  Recommend,
  RecommendPointCard,
  RecommendCoupon,
  RecommendPaymentMethod,
  PointCard,
  Coupon,
  ApplyingMethod,
  PaymentMethod,
} from "../../../fetchers/model";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../../utils/Table";
import axios from "axios";
import API from "../../../config/api";

export default function ListUserHistory({ user_id }: { user_id: string }) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Recommend[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
    }: {
      pageSize: number;
      pageIndex: number;
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      const { data, headers } = await axios.get<Recommend[]>(
        `${API.HISTORY}?page=${
          pageIndex + 1
        }&per_page=${pageSize}&user_id[]=${user_id}`
      );
      if (fetchId === fetchIdRef.current) {
        setData(data);
        setPageCount(Math.ceil(parseInt(headers["total"]) / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const tableColumns = [
    {
      Header: t("history.recommend_at"),
      accessor: "recommend_at" as const,
    },
    {
      Header: t("history.merchant"),
      accessor: "merchant_name" as const,
    },
    {
      Header: t("history.total_amount"),
      accessor: "total_amount" as const,
    },
    {
      Header: t("history.recommend_1"),
      accessor: "recommend_1" as const,
    },
    {
      Header: t("history.applying_methods"),
      accessor: "applying_methods" as const,
    },
  ];

  const renderRecommendItem = (
    item: RecommendPointCard | RecommendCoupon | RecommendPaymentMethod,
    index: number
  ) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center" key={index}>
        <Icon
          as={
            item.method_type === "PointCard"
              ? FiTarget
              : item.method_type === "Coupon"
              ? RiCoupon2Line
              : FiCreditCard
          }
        />
        <Text>{`${item.name} - ${item.saving_amount}¥`}</Text>
      </Stack>
    );
  };

  const renderAppliedItem = (
    item: ApplyingMethod<PointCard | Coupon | PaymentMethod>,
    index: number
  ) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center" key={index}>
        <Icon
          as={
            item.method_type === "PointCard"
              ? FiTarget
              : item.method_type === "Coupon"
              ? RiCoupon2Line
              : FiCreditCard
          }
        />
        <Text>{`${item.method?.name ?? ""} - ${item.saving_amount}¥`}</Text>
      </Stack>
    );
  };

  const tableData = data.map((item) => ({
    recommend_at: new Date(item.recommend_at)
      .toISOString()
      .slice(0, 19)
      .replace(/-/g, "/")
      .replace("T", " "),
    merchant_name: (
      <Link
        color="blue.500"
        href={`/console/merchant/${item.merchant.id}`}
        fontWeight={"semibold"}
      >
        {`${item.merchant.company_name}`}
      </Link>
    ),
    total_amount: item.total_amount,
    recommend_1: item.recommend_data[0] && (
      <Stack spacing={1}>
        {item.recommend_data[0].map((item, index) => {
          return item && renderRecommendItem(item, index);
        })}
      </Stack>
    ),
    applying_methods: item.applying_methods && (
      <Stack spacing={1}>
        {item.applying_methods.map((item, index) => {
          return item && renderAppliedItem(item, index);
        })}
      </Stack>
    ),
  }));

  return (
    <>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
    </>
  );
}
