import React from "react";
import { Stack, Image, Icon, Link } from "@chakra-ui/react";
import { PointCard, UserMethod } from "../../../fetchers/model";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../../utils/Table";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { BsCardImage } from "react-icons/bs";

export default function ListUserPointCard({
  point_cards,
}: {
  point_cards: UserMethod<PointCard>[];
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<UserMethod<PointCard>[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
    }: {
      pageSize: number;
      pageIndex: number;
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        setData(point_cards.slice(startRow, endRow));
        setPageCount(Math.ceil(point_cards.length / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const tableColumns = [
    {
      Header: t("point_card.name"),
      accessor: "name" as const,
    },
    {
      Header: t("point_card.money_conversion"),
      accessor: "money_conversion" as const,
    },
    {
      Header: t("point_card.point_conversion"),
      accessor: "point_conversion" as const,
    },
    {
      Header: t("user.interested"),
      accessor: "interested" as const,
    },
  ];

  const tableData = data.map((item) => ({
    name: (
      <Stack direction="row" spacing={2} alignItems="center">
        {item.method.logo && item.method.logo.id ? (
          <Image
            boxSize="36px"
            objectFit="cover"
            src={item.method.logo.url}
            alt={item.method.name}
          />
        ) : (
          <Icon as={BsCardImage} boxSize="36px" color={"gray.400"} />
        )}
        <Link
          color="blue.500"
          href={`/console/point_card/${item.method.id}`}
          fontWeight={"bold"}
        >
          {item.method.name}
        </Link>
      </Stack>
    ),
    money_conversion: `${item.method.paid_amount} ¥ = ${item.method.point_acquired} points`,
    point_conversion: `${item.method.point_rate} points = ${
      item.method.amount_rate
    } ${item.method.redemption_unit || "¥"}`,
    interested: item.interested && <CheckCircleIcon />,
  }));

  return (
    <>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
    </>
  );
}
