import * as React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DashboardProps } from "../../utils/CommonInterface";
import { PopularChartData } from "../../fetchers/model";
import { ConvertDateToString } from "../../utils/Date";
import { useTranslation } from "react-i18next";
import axios from "axios";
import API from "../../config/api";

ChartJS.register(ArcElement, Tooltip, Legend);

const OTHER_GROUP_INDEX = 6;

export default function PopularChart(props: DashboardProps) {
  const { t } = useTranslation();
  const { merchants, dateFrom, dateTo, bySerie, payment_type } = props;
  const [data, setData] = React.useState<PopularChartData[]>();

  const fetchPopular = async () => {
    const { data: result } = await axios.get<PopularChartData[]>(
      `${API.DASHBOARD_POPULAR}?start_date=${ConvertDateToString(
        dateFrom
      )}&end_date=${ConvertDateToString(dateTo)}&by=${bySerie}${
        (merchants.length > 0 && `&merchant_id=${merchants[0]}`) || ""
      }&payment_type=${payment_type}`
    );
    result.sort((a, b) => b.count - a.count);
    let calc: PopularChartData[];
    if (result.length < OTHER_GROUP_INDEX) calc = result;
    else {
      calc = result.slice(0, OTHER_GROUP_INDEX - 1);
      let otherPopular: PopularChartData = {
        id: "-1",
        name: t("dashboard.popular.others"),
        count: result
          .slice(OTHER_GROUP_INDEX - 1, result.length)
          .reduce((accumulator, object) => {
            return accumulator + object.count;
          }, 0),
      };
      calc.push(otherPopular);
    }
    setData(calc);
  };

  React.useEffect(() => {
    fetchPopular();
  }, [merchants, dateFrom, dateTo, bySerie]);

  if (!data) return <div>Loading...</div>;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: t(`dashboard.popular.${payment_type}`),
      },
    },
  };

  let chartData = {
    labels: data.map((item) => item.name),
    datasets: [
      {
        data: data.map((item) => item.count),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie options={options} data={chartData} />;
}
