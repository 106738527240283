import React from "react";
import { Formik, Field, FieldProps } from "formik";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Heading,
  VStack,
  Stack,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import API from "../../config/api";

export default function CreateMerchant() {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();

  return (
    <Flex h="100vh" direction={"column"}>
      <Heading as="h4" size="lg" mb="4">
        {t("merchant.title.new")}
      </Heading>
      <Box bg="white" rounded="md" w={360} pb={4}>
        <Formik
          initialValues={{
            email: "",
            company_name: "",
            office_name: "",
            representative_name: "",
            status: "waiting_review",
            phone: "",
            postal_code: "",
            country: "",
            province: "",
            district: "",
            address_1: "",
            address_2: "",
            person_in_charge: "",
            tax: "0",
          }}
          onSubmit={async (values, { setErrors }) => {
            setSubmitting(true);

            await axios.post<void>(API.MERCHANT, values);
            toast({
              title: t("merchant.api.created_success"),
              status: "success",
              isClosable: true,
            });
            setSubmitting(false);
            navigate(-1);
          }}
        >
          {({ handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="flex-start">
                <FormControl
                  isRequired
                  isInvalid={!!errors.email && touched.email}
                >
                  <FormLabel htmlFor="email">{t("merchant.email")}</FormLabel>
                  <Field as={Input} id="email" name="email" />
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={!!errors.company_name && touched.company_name}
                >
                  <FormLabel htmlFor="company_name">
                    {t("merchant.company_name")}
                  </FormLabel>
                  <Field as={Input} id="company_name" name="company_name" />
                  <FormErrorMessage>{errors.company_name}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.office_name && touched.office_name}
                >
                  <FormLabel htmlFor="office_name">
                    {t("merchant.office_name")}
                  </FormLabel>
                  <Field as={Input} id="office_name" name="office_name" />
                  <FormErrorMessage>{errors.office_name}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!errors.representative_name && touched.representative_name
                  }
                >
                  <FormLabel htmlFor="representative_name">
                    {t("merchant.representative_name")}
                  </FormLabel>
                  <Field
                    as={Input}
                    id="representative_name"
                    name="representative_name"
                  />
                  <FormErrorMessage>
                    {errors.representative_name}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.phone && touched.phone}>
                  <FormLabel htmlFor="phone">{t("merchant.phone")}</FormLabel>
                  <Field as={Input} id="phone" name="phone" />
                  <FormErrorMessage>{errors.phone}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.postal_code && touched.postal_code}
                >
                  <FormLabel htmlFor="postal_code">
                    {t("merchant.postal_code")}
                  </FormLabel>
                  <Field as={Input} id="postal_code" name="postal_code" />
                  <FormErrorMessage>{errors.postal_code}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.country && touched.country}>
                  <FormLabel htmlFor="country">
                    {t("merchant.country")}
                  </FormLabel>
                  <Field as={Input} id="country" name="country" />
                  <FormErrorMessage>{errors.country}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.province && touched.province}>
                  <FormLabel htmlFor="province">
                    {t("merchant.province")}
                  </FormLabel>
                  <Field as={Input} id="province" name="province" />
                  <FormErrorMessage>{errors.province}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.district && touched.district}>
                  <FormLabel htmlFor="district">
                    {t("merchant.district")}
                  </FormLabel>
                  <Field as={Input} id="district" name="district" />
                  <FormErrorMessage>{errors.district}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.address_1 && touched.address_1}
                >
                  <FormLabel htmlFor="address_1">
                    {t("merchant.address_1")}
                  </FormLabel>
                  <Field as={Input} id="address_1" name="address_1" />
                  <FormErrorMessage>{errors.address_1}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!errors.address_2 && touched.address_2}
                >
                  <FormLabel htmlFor="address_2">
                    {t("merchant.address_2")}
                  </FormLabel>
                  <Field as={Input} id="address_2" name="address_2" />
                  <FormErrorMessage>{errors.address_2}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    !!errors.person_in_charge && touched.person_in_charge
                  }
                >
                  <FormLabel htmlFor="person_in_charge">
                    {t("merchant.person_in_charge")}
                  </FormLabel>
                  <Field
                    as={Input}
                    id="person_in_charge"
                    name="person_in_charge"
                  />
                  <FormErrorMessage>{errors.person_in_charge}</FormErrorMessage>
                </FormControl>
                <Field name="tax">
                  {({ field, form }: FieldProps) => {
                    return (
                      <FormControl
                        isRequired
                        isInvalid={!!errors.tax && touched.tax}
                      >
                        <FormLabel htmlFor="tax">{t("merchant.tax")}</FormLabel>
                        <NumberInput
                          {...field}
                          id="tax"
                          name="tax"
                          onChange={(val) =>
                            form.setFieldValue(field.name, val)
                          }
                        >
                          <NumberInputField />
                        </NumberInput>
                        <FormErrorMessage>{errors.tax}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
                <Stack direction="row" spacing={4} align="center">
                  <Button
                    colorScheme="blue"
                    variant="solid"
                    type="submit"
                    isLoading={submitting}
                    loadingText={t("action.loading.creating")}
                  >
                    {t("action.create")}
                  </Button>
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => navigate(-1)}
                    disabled={submitting}
                  >
                    {t("action.cancel")}
                  </Button>
                </Stack>
              </VStack>
            </form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
}
