import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  Box,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface ConfirmationDialogProps {
  title: string;
  description: string;
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

export default function ConfirmationDialog({
  title,
  description,
  onConfirm,
  children,
}: ConfirmationDialogProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <AlertDialogBody>{description}</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant={"ghost"}>
                {t("action.cancel")}
              </Button>
              <Button
                colorScheme="blue"
                onClick={(e) => {
                  onConfirm(e);
                  onClose();
                }}
                ml={3}
              >
                {t("action.delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
