import React from "react";
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Divider,
  Link,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Recommend,
  RecommendPointCard,
  RecommendCoupon,
  RecommendPaymentMethod,
  PointCard,
  Coupon,
  ApplyingMethod,
  PaymentMethod,
} from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import axios from "axios";
import API from "../../config/api";
import { TimeIcon } from "@chakra-ui/icons";
import { BiStore } from "react-icons/bi";
import { MdPriceCheck } from "react-icons/md";
import { FiCreditCard, FiTarget } from "react-icons/fi";
import { RiCoupon2Line } from "react-icons/ri";

export default function ShowRecommend() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Recommend>();
  const { t } = useTranslation();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<Recommend>(
      `${API.HISTORY}/${params.token}`
    );
    setData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  const renderRecommendItem = (
    item: RecommendPointCard | RecommendCoupon | RecommendPaymentMethod,
    index: number
  ) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center" key={index}>
        <Icon
          as={
            item.method_type === "PointCard"
              ? FiTarget
              : item.method_type === "Coupon"
              ? RiCoupon2Line
              : FiCreditCard
          }
        />
        <Text>{`${item.name} - ${item.saving_amount}¥`}</Text>
      </Stack>
    );
  };

  const renderAppliedItem = (
    item: ApplyingMethod<PointCard | Coupon | PaymentMethod>,
    index: number
  ) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center" key={index}>
        <Icon
          as={
            item.method_type === "PointCard"
              ? FiTarget
              : item.method_type === "Coupon"
              ? RiCoupon2Line
              : FiCreditCard
          }
        />
        <Text>{`${item.method?.name ?? ""} - ${item.saving_amount}¥`}</Text>
      </Stack>
    );
  };

  return (
    <Flex h="100vh" direction={"column"}>
      <Flex justifyContent="space-between" alignItems={"center"}>
        <Box>
          <Link
            color="blue.500"
            href={`/console/recommend_history`}
            fontWeight={"semibold"}
          >
            {t("action.backToList")}
          </Link>
          <Heading as="h4" size="lg" mb="4">
            {`${data.user.first_name} ${data.user.last_name} @ ${
              data.merchant.company_name
            } - ${new Date(data.recommend_at)
              .toISOString()
              .slice(0, 19)
              .replace(/-/g, "/")
              .replace("T", " ")}`}
          </Heading>
        </Box>
      </Flex>
      <Box bg="white">
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <TimeIcon />
              <Text>
                {new Date(data.recommend_at)
                  .toISOString()
                  .slice(0, 19)
                  .replace(/-/g, "/")
                  .replace("T", " ")}
              </Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <Icon as={BiStore} />
              <Text>{data.merchant.company_name}</Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <Icon as={MdPriceCheck} />
              <Text>{data.total_amount}</Text>
            </Stack>
          </Stack>
        </SimpleGrid>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Box>
            <Text fontWeight={"semibold"}>{t("history.recommend_1")}</Text>
            <Text fontSize={"sm"}>
              {data.recommend_data[0] && (
                <Stack spacing={1}>
                  {data.recommend_data[0].map((item, index) => {
                    return item && renderRecommendItem(item, index);
                  })}
                </Stack>
              )}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={"semibold"}>{t("history.recommend_2")}</Text>
            <Text fontSize={"sm"}>
              {data.recommend_data[1] && (
                <Stack spacing={1}>
                  {data.recommend_data[1].map((item, index) => {
                    return item && renderRecommendItem(item, index);
                  })}
                </Stack>
              )}
            </Text>
          </Box>
          <Box>
            <Text fontWeight={"semibold"}>{t("history.recommend_3")}</Text>
            <Text fontSize={"sm"}>
              {data.recommend_data[2] && (
                <Stack spacing={1}>
                  {data.recommend_data[2].map((item, index) => {
                    return item && renderRecommendItem(item, index);
                  })}
                </Stack>
              )}
            </Text>
          </Box>
        </SimpleGrid>
        <Divider mt={4} mb={4} />
        <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
          <Box>
            <Text fontWeight={"semibold"}>{t("history.applying_methods")}</Text>
            <Text fontSize={"sm"}>
              {data.applying_methods && (
                <Stack spacing={1}>
                  {data.applying_methods.map((item, index) => {
                    return item && renderAppliedItem(item, index);
                  })}
                </Stack>
              )}
            </Text>
          </Box>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}
