import * as React from "react";
import { auth } from "../config/firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  UserCredential,
  setPersistence,
  indexedDBLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";

interface AuthContextType {
  user: any;
  login: (
    email: string,
    password: string,
    rememberMe: boolean
  ) => Promise<UserCredential>;
  signUp: (email: string, password: string) => void;
  signOut: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(true);

  function login(email: string, password: string, rememberMe: boolean) {
    setPersistence(
      auth,
      rememberMe ? indexedDBLocalPersistence : browserSessionPersistence
    );
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signOut() {
    localStorage.removeItem("access_token");
    return auth.signOut();
  }

  function signUp(email: string, password: string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setUser(user);
      user && localStorage.setItem("access_token", await user.getIdToken());
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  let value = { user, login, signUp, signOut };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}
