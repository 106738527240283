import React from "react";
import {
  Heading,
  Flex,
  Button,
  IconButton,
  Stack,
  Tag,
  TagLabel,
  Link,
} from "@chakra-ui/react";
import { FiEdit2, FiPlus } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { Notification } from "../../fetchers/model";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "../../utils/Confirmation";
import { useTranslation } from "react-i18next";
import { Table as AITable } from "../../utils/Table";
import axios from "axios";
import { Select } from "chakra-react-select";
import { FilterValue, SortBy } from "../../utils/CommonInterface";
import { ConvertDateToString } from "../../utils/Date";
import API from "../../config/api";

export default function ListNotification() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Notification[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    async ({
      pageSize,
      pageIndex,
      globalFilter,
      filters,
      sortBy,
    }: {
      pageSize: number;
      pageIndex: number;
      globalFilter: string;
      filters: FilterValue[];
      sortBy: SortBy[];
    }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      const recipientFilters = filters.filter(
        (x) => x.id === "recipients" && x.value.length > 0
      );
      let recipientParams =
        (recipientFilters.length > 0 &&
          recipientFilters[0].value
            .map((x) => `&recipients[]=${x}`)
            .join("")) ||
        "";

      let sortByParam =
        (sortBy.length > 0 &&
          `&order=${sortBy[0].desc ? "-" : ""}${sortBy[0].id}`) ||
        "";
      const { data, headers } = await axios.get<Notification[]>(
        `${API.NOTIFICATION}?page=${
          pageIndex + 1
        }&per_page=${pageSize}${recipientParams}${sortByParam}`
      );
      if (fetchId === fetchIdRef.current) {
        setData(data);
        setPageCount(Math.ceil(parseInt(headers["total"]) / pageSize));

        setLoading(false);
      }
    },
    []
  );

  const recipientOptions = ["users", "merchants"].map(function (val) {
    return {
      label: t(`notification.recipients.${val}`),
      value: val,
    };
  });

  const tableColumns = [
    {
      Header: t("notification.title.col_name"),
      accessor: "title" as const,
      disableFilters: true,
    },
    {
      Header: t("notification.recipients.title"),
      accessor: "recipients" as const,
      FilterHeader: t("notification.recipients.title"),
      Filter: ({ column }: { column: any }) => (
        <Select
          size="sm"
          isMulti
          name={column.id}
          closeMenuOnSelect={false}
          value={recipientOptions.filter(
            (x) => column.filterValue && column.filterValue.includes(x.value)
          )}
          options={recipientOptions}
          onChange={(option) => column.setFilter(option.map((x) => x.value))}
        />
      ),
      filterable: true,
    },
    {
      Header: t("notification.scheduled_send_time"),
      accessor: "scheduled_send_time" as const,
      disableFilters: true,
    },
    {
      Header: "",
      accessor: "action" as const,
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const tableData = data.map((item) => ({
    title: (
      <Link
        color="blue.500"
        href={`/console/notification/${item.id}`}
        fontWeight={"bold"}
      >
        {`${item.title}`}
      </Link>
    ),
    recipients: (
      <Tag
        size={"sm"}
        borderRadius="full"
        variant={item.recipients === "merchants" ? "outline" : "solid"}
        colorScheme="blue"
      >
        <TagLabel>{t(`notification.recipients.${item.recipients}`)}</TagLabel>
      </Tag>
    ),
    scheduled_send_time:
      item.scheduled_send_time &&
      ConvertDateToString(new Date(item.scheduled_send_time)),
    action: (
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <IconButton
          icon={<FiEdit2 />}
          aria-label={t("action.edit")}
          variant="link"
          onClick={() => navigate(`/console/notification/${item.id}/edit`)}
        />
        <ConfirmationDialog
          title={t("notification.delete.title")}
          description={t("notification.delete.description", {
            notification_name: item.title,
          })}
          onConfirm={() => deleteItem(item.id)}
        >
          <IconButton
            icon={<RiDeleteBin5Line />}
            aria-label={t("action.delete")}
            variant="link"
          />
        </ConfirmationDialog>
      </Stack>
    ),
  }));

  const deleteItem = async (itemId: string) => {
    await axios.delete<void>(`${API.NOTIFICATION}/${itemId}`);
    setData(data.filter((x) => x.id !== itemId));
  };

  return (
    <>
      <Flex justifyContent="space-between">
        <Heading as="h4" size="lg" mb="4">
          {t("notification.title.name")}
        </Heading>
        <Button
          leftIcon={<FiPlus />}
          colorScheme="blue"
          variant="solid"
          onClick={() => navigate("/console/notification/new")}
        >
          {t("action.new")}
        </Button>
      </Flex>
      <AITable
        columns={tableColumns}
        data={tableData}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        useSearch={true}
        disableFilters={false}
        disableSortBy={false}
      />
    </>
  );
}
