import React from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  SimpleGrid,
  Icon,
  Link,
} from "@chakra-ui/react";
import {
  CheckIcon,
  InfoIcon,
  InfoOutlineIcon,
  TimeIcon,
} from "@chakra-ui/icons";
import { FiEdit2, FiCode } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useToast } from "@chakra-ui/react";
import { Term } from "../../fetchers/model";
import { DataLoading, DataNotReach } from "../../utils";
import { ConvertDateToString } from "../../utils/Date";
import axios from "axios";
import API from "../../config/api";
import { Markup } from "interweave";

export default function ShowTerm() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<Term>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toast = useToast();
  let params = useParams();

  const fetchData = async () => {
    const { data: result } = await axios.get<Term>(`${API.TERM}/${params.id}`);
    setData(result);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <DataLoading />;

  if (!data) return <DataNotReach />;

  const publishTerm = async () => {
    await axios.patch<void>(`${API.TERM}/${params.id}`, {
      status: "published",
      term_content: data.term_content,
    });
    toast({
      title: t("merchant.api.update_status_success"),
      status: "success",
      isClosable: true,
    });
    window.location.reload();
  };

  return (
    <Flex h="100vh" direction={"column"}>
      <Flex justifyContent="space-between" alignItems={"center"}>
        <Box>
          <Link color="blue.500" href={`/console/term`} fontWeight={"semibold"}>
            {t("action.backToList")}
          </Link>
          <Heading as="h4" size="lg" mb="4">
            {t("term.title.show", { id: data.version_no })}
          </Heading>
        </Box>
        <Box>
          <Stack direction={"row"} spacing={4}>
            <Button
              size={"sm"}
              leftIcon={<FiEdit2 />}
              colorScheme="blue"
              variant="ghost"
              onClick={() => navigate(`/console/term/${data.id}/edit`)}
            >
              {t("action.edit")}
            </Button>
            {data.status === "draft" && (
              <Button
                size={"sm"}
                leftIcon={<CheckIcon />}
                colorScheme="yellow"
                variant="solid"
                onClick={() => publishTerm()}
              >
                {t("term.action.publish")}
              </Button>
            )}
          </Stack>
        </Box>
      </Flex>
      <SimpleGrid columns={{ md: 3, sm: 2 }} gap={6}>
        <Box>
          <Stack direction={"column"} spacing={2}>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <Icon as={FiCode} />
              <Text>{data.term_no}</Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              {data.force_read ? <InfoIcon /> : <InfoOutlineIcon />}
              <Text>
                {data.force_read
                  ? t("term.force_read.yes")
                  : t("term.force_read.no")}
              </Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems="center">
              <TimeIcon />
              <Text>
                {data.status === "draft"
                  ? t("term.status.draft_phase")
                  : t("term.status.published_phase", {
                      publish_at:
                        data.publish_at &&
                        ConvertDateToString(new Date(data.publish_at)),
                    })}
              </Text>
            </Stack>
          </Stack>
        </Box>
      </SimpleGrid>
      <Box
        mt={4}
        p={4}
        border="1px"
        borderColor="gray.200"
        rounded={"md"}
        mb={4}
      >
        <Markup content={data.term_content} />
      </Box>
    </Flex>
  );
}
