import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { DashboardProps } from "../../utils/CommonInterface";
import { ChartData } from "../../fetchers/model";
import { ConvertDateToString } from "../../utils/Date";
import axios from "axios";
import API from "../../config/api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: "Visitor",
    },
  },
};

export default function VisitorChart(props: DashboardProps) {
  const { merchants, dateFrom, dateTo, bySerie } = props;
  const [data, setData] = React.useState<ChartData[]>();

  const fetchVisitor = async () => {
    const { data: result } = await axios.get<ChartData[]>(
      `${API.DASHBOARD_VISITOR}?start_date=${ConvertDateToString(
        dateFrom
      )}&end_date=${ConvertDateToString(dateTo)}&by=${bySerie}${
        (merchants.length > 0 && `&merchant_id=${merchants[0]}`) || ""
      }`
    );
    setData(result);
  };

  React.useEffect(() => {
    fetchVisitor();
  }, [merchants, dateFrom, dateTo, bySerie]);

  if (!data) return <div>Loading...</div>;

  let chartData = {
    labels: data.map((item) => item.x),
    datasets: [
      {
        label: "Visitor Dataset",
        data: data.map((item) => item.y),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return <Line options={options} data={chartData} />;
}
