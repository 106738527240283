import { User } from "../../../fetchers/model";
import * as React from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface ImageLightbox {
  src: string;
  title: string;
  description?: string;
}

interface ShowLight {
  startIndex: number;
}

export default function CaptionCarousel({ user }: { user: User }) {
  const { t } = useTranslation();
  const [showLight, setShowLight] = React.useState<ShowLight>();

  const showLightBox = (index: number) => {
    setShowLight({
      startIndex: index,
    });
  };

  const hideLightBox = () => {
    setShowLight(undefined);
  };

  let cards: ImageLightbox[] = [];

  user.doc_front_end &&
    cards.push({
      src: user.doc_front_end.url,
      title: user.doc_front_end.name,
    });
  user.doc_back_end &&
    cards.push({
      src: user.doc_back_end.url,
      title: user.doc_back_end.name,
    });
  user.doc_selfie &&
    cards.push({
      src: user.doc_selfie.url,
      title: user.doc_selfie.name,
    });

  return (
    <Box>
      <Flex direction={"row"} gap={4}>
        {cards.length === 0 && <Text>{t("user.kyc_status.no_image")}</Text>}
        {cards.map((image, index) => (
          <Box borderColor="gray.200" borderWidth="1px" key={index}>
            <Image
              src={image.src}
              alt={"Excessive length"}
              width="200"
              height="150"
              onClick={() => showLightBox(index)}
            />
          </Box>
        ))}
      </Flex>
      {showLight && (
        <Lightbox
          images={cards.map((img) => ({ url: img.src, title: img.title }))}
          startIndex={showLight.startIndex}
          onClose={hideLightBox}
        />
      )}
    </Box>
  );
}
